<template>
    <AppScheduleFormItemGroup @addScheduleRow="addScheduleRow">
        <Draggable
            v-model="formValues.scheduleRows"
            :disabled="loading > 0"
            handle=".button--draggable"
            @change="changeSchedule"
        >
            <AppScheduleFormItem
                v-for="row in formValues.scheduleRows"
                :key="`${row.index}-${row.id}`"
                context="schedule"
                :delete-item="row.delete"
                :disabled="loading > 0"
                :enable-end-time="
                    !lastNotDeleted || row.index === lastNotDeleted.index
                "
                :end-time="row.endTime"
                :form-index="row.index"
                :form-errors="formErrors.scheduleRows.forms"
                :grades="
                    schedule.grade
                        ? [schedule.grade.grade]
                        : schedule.groupSchedule.group.grades
                "
                :notes.sync="row.notes"
                :theme.sync="row.theme"
                :purpose.sync="row.purpose"
                :start-time="row.startTime"
                :subject-description.sync="row.subjectDescription"
                :subject-id="row.subjectId"
                :sub-subject-id.sync="row.subSubjectId"
                :is-bundle="row.isBundle"
                :bundle-rows="row.bundleRows"
                :is-final="scheduleStatus === 'final'"
                @update:startTime="
                    row.startTime = $event;
                    updateEndTimes();
                    updateBundleRows();
                "
                @update:endTime="
                    row.endTime = $event;
                    updateBundleRows();
                "
                @update:subjectId="
                    row.subjectId = $event;
                    updateBundleRows();
                "
                @update:isBundle="
                    row.isBundle = $event;
                    updateBundleRows();
                "
                @update:deleteItem="
                    row.delete = $event;
                    updateEndTimes();
                    updateBundleRows();
                "
                @addScheduleBundleRow="addScheduleBundleRow(row)"
            />
        </Draggable>
    </AppScheduleFormItemGroup>
</template>

<script>
import AppScheduleFormItem from "@/components/AppScheduleFormItem";
import AppScheduleFormItemGroup from "@/components/AppScheduleFormItemGroup";
import Draggable from "vuedraggable";
import FormSave from "@/mixins/FormSave";
import Schedules from "@/mixins/Schedules";

import { cloneDeep } from "lodash";
import { consoleError } from "@/console";
import { getScheduleRows } from "@/utils/getScheduleRows";
import gql from "graphql-tag";

export default {
    name: "AppScheduleUpdate",
    components: {
        AppScheduleFormItem,
        AppScheduleFormItemGroup,
        Draggable
    },
    mixins: [FormSave, Schedules("scheduleRows")],
    props: {
        scheduleId: {
            type: String,
            required: true
        },
        scheduleStatus: {
            type: String,
            required: true,
            validator: value => {
                return ["draft", "final"].includes(value);
            }
        }
    },
    data() {
        return {
            emptyFormErrors: {
                scheduleRows: {
                    nonFormErrors: [],
                    forms: []
                }
            },
            emptyScheduleRow: {
                index: null,
                id: "",
                startTime: null,
                endTime: null,
                notes: "",
                theme: "",
                purpose: "",
                subjectBundleId: "",
                subjectId: "",
                subSubjectId: "",
                subjectDescription: "",
                isBundle: false,
                bundleRows: [],
                delete: false
            },
            formValues: {
                scheduleRows: []
            },
            schedule: {
                grade: {},
                groupSchedule: { group: {} },
                scheduleRows: []
            },
            scheduleTemplate: {}
        };
    },
    computed: {
        numericId() {
            return atob(this.scheduleId).split(":")[1];
        }
    },
    apollo: {
        schedule: {
            query: gql`
                query scheduleAppScheduleUpdate($id: ID!) {
                    schedule(id: $id) {
                        id
                        status
                        grade {
                            id
                            grade
                        }
                        groupSchedule {
                            id
                            date
                            group {
                                id
                                grades
                            }
                        }
                        scheduleRows {
                            edges {
                                node {
                                    id
                                    startTime
                                    endTime
                                    notes
                                    theme
                                    purpose
                                    subjectBundle {
                                        id
                                        name
                                    }
                                    subject {
                                        id
                                        name
                                        parent {
                                            id
                                            name
                                        }
                                    }
                                    subjectDescription
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.scheduleId
                };
            },
            update(data) {
                if (data.schedule) {
                    const schedule = { ...data.schedule };
                    schedule.scheduleRows = getScheduleRows(
                        schedule.scheduleRows.edges,
                        node => ({
                            ...cloneDeep(this.emptyScheduleRow),
                            id: node.id,
                            startTime: this.formatTime(node.startTime),
                            endTime: this.formatTime(node.endTime),
                            notes: node.notes,
                            theme: node.theme,
                            purpose: node.purpose,
                            subjectId: node.subject
                                ? node.subject.parent
                                    ? node.subject.parent.id
                                    : node.subject.id
                                : null,
                            subSubjectId:
                                node.subject && node.subject.parent
                                    ? node.subject.id
                                    : null,
                            subjectDescription: node.subjectDescription
                        }),
                        node => ({
                            ...cloneDeep(this.emptyScheduleRow),
                            id: node.id,
                            startTime: this.formatTime(node.startTime),
                            endTime: this.formatTime(node.endTime),
                            subjectId: node.subjectBundle.id
                        })
                    );
                    this.$nextTick(() => {
                        this.setFormValues(schedule);
                    });
                    return schedule;
                } else {
                    return this.schedule;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de planning."
                    ),
                    type: "error"
                });
            }
        },
        scheduleTemplate: {
            query: gql`
                query scheduleTemplateAppScheduleUpdate(
                    $weekday: Int!
                    $grade: Int
                    $groupId: ID!
                ) {
                    allScheduleTemplates(
                        weekday: $weekday
                        grade: $grade
                        groupId: $groupId
                        first: 1
                    ) {
                        edges {
                            node {
                                id
                                scheduleTemplateRows {
                                    edges {
                                        node {
                                            id
                                            startTime
                                            endTime
                                            subjectBundle {
                                                id
                                                name
                                            }
                                            subject {
                                                id
                                                name
                                            }
                                            subjectDescription
                                            notes
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    weekday: this.$moment(
                        this.schedule.groupSchedule.date
                    ).weekday(),
                    grade: this.schedule.grade && this.schedule.grade.grade,
                    groupId: this.schedule.groupSchedule.group.id
                };
            },
            update(data) {
                if (data.allScheduleTemplates) {
                    if (data.allScheduleTemplates.edges.length === 0) return {};

                    const schedule = {
                        ...data.allScheduleTemplates.edges[0].node
                    };
                    schedule.scheduleRows = getScheduleRows(
                        schedule.scheduleTemplateRows.edges,
                        node => ({
                            ...cloneDeep(this.emptyScheduleRow),
                            startTime: this.formatTime(node.startTime),
                            endTime: this.formatTime(node.endTime),
                            notes: node.notes,
                            subjectId: node.subject ? node.subject.id : null,
                            subjectDescription: node.subjectDescription
                        }),
                        node => ({
                            ...cloneDeep(this.emptyScheduleRow),
                            startTime: this.formatTime(node.startTime),
                            endTime: this.formatTime(node.endTime),
                            subjectId: node.subjectBundle.id
                        })
                    );
                    this.setFormValues(schedule);
                    return schedule;
                } else {
                    return this.scheduleTemplate;
                }
            },
            skip() {
                return (
                    !this.schedule.id || this.schedule.scheduleRows.length > 0
                );
            }
        }
    },
    watch: {
        "formValues.scheduleRows"(rows) {
            if (rows.length > 0) {
                this.$emit("disable-finalize", false);
            }
        }
    },
    methods: {
        getMutation() {
            const initialForms = this.schedule.scheduleRows.reduce((acc, x) => {
                return acc + x.bundleRows.length;
            }, 0);
            const scheduleRows = this.formValues.scheduleRows
                .reduce((acc, x) => {
                    if (x.isBundle) {
                        return acc.concat(x.bundleRows);
                    } else {
                        // add remaining bundle rows to delete, this will be
                        // needed when a schedule item switches from bundle to non bundle
                        x.bundleRows.forEach((val, i) => {
                            if (i !== 0) {
                                acc.push({
                                    ...val,
                                    delete: true
                                });
                            }
                        });
                        acc.push(x);
                        return acc;
                    }
                }, [])
                .sort((a, b) => {
                    return a.index - b.index;
                });
            const input = {
                id: this.numericId,
                status: this.scheduleStatus.toLowerCase(),
                scheduleRowsInitialForms: initialForms,
                scheduleRowsTotalForms: scheduleRows.length,
                scheduleRows: scheduleRows.map(val => {
                    return {
                        id: val.id ? atob(val.id).split(":")[1] : "",
                        startTime: val.startTime
                            ? this.formatTime(val.startTime)
                            : null,
                        endTime: val.endTime
                            ? this.formatTime(val.endTime)
                            : null,
                        notes: val.notes,
                        theme: val.theme,
                        purpose: val.purpose,
                        subjectBundle: val.subjectBundleId
                            ? this.getRawId(val.subjectBundleId)
                            : "",
                        subject:
                            val.subSubjectId || val.subjectId
                                ? this.getRawId(
                                      val.subSubjectId || val.subjectId
                                  )
                                : "",
                        subjectDescription: val.subjectDescription,
                        delete: val.delete
                    };
                })
            };

            return {
                mutation: gql`
                    mutation scheduleAppScheduleUpdate(
                        $input: ScheduleMutationInput!
                    ) {
                        schedule(input: $input) {
                            schedule {
                                id
                                status
                                scheduleRows {
                                    edges {
                                        node {
                                            id
                                            startTime
                                            endTime
                                            notes
                                            theme
                                            purpose
                                            subjectBundle {
                                                id
                                                name
                                            }
                                            subject {
                                                id
                                                name
                                                parent {
                                                    id
                                                    name
                                                }
                                            }
                                            subjectDescription
                                            isBreak
                                            evaluation
                                        }
                                    }
                                }
                            }
                            errors {
                                ... on ErrorType {
                                    field
                                    messages
                                }
                                ... on ErrorTypeFormset {
                                    field
                                    formIndex
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            };
        }
    }
};
</script>
