<template>
    <AppModal
        ref="modal"
        persistent
        :value="!!value"
        variant="bordered"
        has-form
        @modalSubmit="formSubmit"
        @input="$emit('input', false)"
    >
        <template #title>
            <translate>Overdrachtsnotitie wijzigen</translate>
        </template>

        <AppInputTextarea
            v-model="formValues.notes"
            :disabled="loading > 0"
            :error-messages="formErrors.notes"
            required
            :counter="560"
            size="large"
        />

        <template #footer>
            <AppButtonGroup variant="between">
                <AppLink
                    v-translate
                    as="button"
                    type="button"
                    variant="bold font-primary"
                    @click.native="$emit('input', false)"
                >
                    Annuleren
                </AppLink>
                <AppButton
                    v-translate
                    variant="secondary large"
                    :disabled="!onLine"
                    :loading="loading > 0"
                    type="submit"
                >
                    Opslaan
                </AppButton>
            </AppButtonGroup>
        </template>
    </AppModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppButtonGroup from "@/components/AppButtonGroup";
import AppInputTextarea from "@/components/AppInputTextarea";
import AppLink from "@/components/AppLink";
import AppModal from "@/components/AppModal";
import FormSave from "@/mixins/FormSave";
import gql from "graphql-tag";

export default {
    name: "AppTransferNoteModal",
    components: {
        AppButton,
        AppButtonGroup,
        AppLink,
        AppModal,
        AppInputTextarea
    },
    mixins: [FormSave],
    props: {
        value: {
            type: Boolean,
            default: false
        },
        transferNoteId: {
            type: String,
            required: true
        },
        groupId: {
            type: String,
            required: true
        },
        notes: {
            type: String,
            required: true
        }
    },
    data(vm) {
        return {
            formValues: {
                notes: vm.notes
            },
            emptyFormErrors: {
                notes: []
            }
        };
    },
    computed: mapGetters("online", ["onLine"]),
    watch: {
        value(value) {
            if (!value) {
                this.formValues.notes = this.notes;
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        getMutation() {
            const input = {
                id: atob(this.transferNoteId).split(":")[1],
                group: atob(this.groupId).split(":")[1],
                notes: this.formValues.notes
            };

            return {
                mutation: gql`
                    mutation trasferNoteModal(
                        $input: TransferNoteMutationInput!
                    ) {
                        transferNote(input: $input) {
                            transferNote {
                                id
                                teacher {
                                    id
                                    fullName
                                }
                                notes
                                modified
                                created
                            }
                            errors {
                                field
                                messages
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            };
        },
        formSuccess() {
            this.showMessage({
                message: this.$gettext("Overdracht is succesvol opgeslagen."),
                type: "success"
            });
            this.$emit("input", false);
        }
    }
};
</script>
