<template>
    <AppLayoutInner>
        <AppWrapper variant="normal">
            <AppEducationPlanGroup
                v-for="(insights, key) in allInsightsGroupedBySubjectAndStudent"
                :key="key"
                :title="key !== '.parent' ? key : ''"
                :education-plans="insights"
            />
        </AppWrapper>
    </AppLayoutInner>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppEducationPlanGroup from "@/components/AppEducationPlanGroup";
import AppLayoutInner from "@/components/AppLayoutInner";
import AppWrapper from "@/components/AppWrapper";
import GroupedInsights from "@/mixins/GroupedInsights";

import { cloneDeep } from "lodash";
import { consoleError } from "@/console";
import gql from "graphql-tag";

const insightFields = `
    id
    goal
    method
    educationPlanSubject {
        id
        name
        parent {
            id
            name
        }
    }
    group {
        id
    }
    expired
    bulkId
    insightId
    student {
        id
        fullName
    }
`;

export default {
    name: "EducationPlansDetail",
    components: { AppWrapper, AppLayoutInner, AppEducationPlanGroup },
    mixins: [GroupedInsights],
    props: {
        groupId: {
            type: String,
            required: true
        },
        subjectId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allInsights: {},
            loading: 0
        };
    },
    computed: {
        ...mapGetters("insightmodal", [
            "createdInsights",
            "expiredInsights",
            "deletedInsights",
            "updatedInsights"
        ]),
        ...mapGetters("online", ["onLine"]),
        allInsightsGroupedBySubjectAndStudent() {
            const allInsightsGroupedBySubjectAndStudent = this.allInsightsGroupedByBulkId.reduce(
                (acc, insight) => {
                    const subjectGroupKey = insight.educationPlanSubject.parent
                        ? insight.educationPlanSubject.name
                        : ".parent";
                    if (
                        !Object.prototype.hasOwnProperty.call(
                            acc,
                            subjectGroupKey
                        )
                    ) {
                        acc[subjectGroupKey] = [];
                    }
                    acc[subjectGroupKey].push(insight);

                    return acc;
                },
                {}
            );

            return Object.keys(allInsightsGroupedBySubjectAndStudent)
                .sort()
                .reduce((acc, key) => {
                    acc[key] = allInsightsGroupedBySubjectAndStudent[key];
                    return acc;
                }, {});
        }
    },
    apollo: {
        allInsights: {
            query: gql`
                query(
                    $groupId: ID,
                    $subjectId: ID
                ) {
                    allInsights(
                        groupId: $groupId
                        insightType: ["education_plan"],
                        subjectId: $subjectId
                        subjectIncludeChildren: true
                        notExpired: false
                        noCopies: true
                    ) {
                        edges {
                            node {
                                ... on Insight {
                                    ${insightFields}
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    groupId: this.groupId,
                    subjectId: this.subjectId
                };
            },
            update(data) {
                if (data.allInsights) {
                    return data.allInsights.edges.map(x => {
                        const node = cloneDeep(x.node);
                        node.students = [node.student];
                        return node;
                    });
                } else {
                    return this.allInsights;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van inzichten."
                    ),
                    type: "error"
                });
            }
        }
    },
    watch: {
        createdInsights() {
            this.refetchInsights();
        },
        expiredInsights() {
            this.refetchInsights();
        },
        deletedInsights() {
            this.refetchInsights();
        },
        updatedInsights() {
            this.refetchInsights();
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        refetchInsights() {
            this.$apollo.queries.allInsights.refetch();
        }
    }
};
</script>
