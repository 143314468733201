<template>
    <div>
        <AppSingleTextareaForm
            v-model="formValues.transfer"
            :counter="560"
            :disabled="loading > 0 || !onLine"
            field="transfer"
            :form-mutation="transferMutation"
            icon="exchange"
            :submit-text="$gettext('Toevoegen')"
            @formsuccess="
                formValues.transfer = '';
                formSuccess();
            "
        />

        <template v-if="allTransferNotes.length > 0">
            <AppHeader>
                <AppTitle v-translate as="h2" size="small">
                    Afgelopen overdrachtsnotities
                </AppTitle>
            </AppHeader>

            <div class="overview-notes">
                <div
                    v-for="(note, index) in allTransferNotes"
                    :key="note.id"
                    class="overview-note"
                >
                    <AppArticle class="overview-note__body">
                        {{ note.notes }}
                    </AppArticle>

                    <div class="overview-note__footer">
                        <div class="overview-note__info">
                            <span>{{ note.teacher.fullName }}</span>
                            <span>{{
                                note.created | moment("D MMMM YYYY")
                            }}</span>
                        </div>

                        <AppButtonGroup
                            v-if="isAdmin || me.id === note.teacher.id"
                            class="overview-note__options"
                        >
                            <AppButton
                                v-translate
                                icon="edit"
                                icon-size="regular"
                                variant="clean"
                                @click="showTransferNoteModal(index)"
                            />
                            <AppButton
                                v-translate
                                icon="trash"
                                icon-size="regular"
                                variant="clean"
                                @click="confirmDeleteTransferNote(index)"
                            />
                        </AppButtonGroup>
                    </div>

                    <AppTransferNoteModal
                        v-if="isAdmin || me.id === note.teacher.id"
                        v-model="showTransferNoteModals[index]"
                        :transfer-note-id="note.id"
                        :group-id="groupId"
                        :notes="note.notes"
                    />
                </div>
            </div>

            <AppButton
                v-if="transferNotesHasNextPage"
                variant="link link-underline"
                @click="transferNotesLimit = null"
            >
                <translate>
                    Bekijk alle overdrachtsnotities
                </translate>
            </AppButton>
        </template>
        <template v-else-if="!loading">
            <AppOverviewPlaceholder
                variant="fixed fixed-small text-small background"
                class="u-margin-horizontal-auto"
            >
                <AppTitle v-translate class="overview-placeholder__title">
                    Geen notities
                </AppTitle>
                <p v-translate>
                    Er zijn nog geen overdrachtsnotities ingevuld bij deze klas.
                </p>
            </AppOverviewPlaceholder>
        </template>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import AppArticle from "@/components/AppArticle.vue";
import AppButton from "@/components/AppButton";
import AppButtonGroup from "@/components/AppButtonGroup.vue";
import AppHeader from "@/components/AppHeader";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppSingleTextareaForm from "@/components/AppSingleTextareaForm";
import AppTitle from "@/components/AppTitle.vue";
import AppTransferNoteModal from "@/components/AppTransferNoteModal";

import FormSave from "@/mixins/FormSave";
import { consoleError } from "@/console";
import gql from "graphql-tag";

export default {
    name: "GroupOverviewTransfers",
    components: {
        AppArticle,
        AppButtonGroup,
        AppButton,
        AppHeader,
        AppOverviewPlaceholder,
        AppSingleTextareaForm,
        AppTitle,
        AppTransferNoteModal
    },
    mixins: [FormSave],
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allTransferNotes: [],
            me: {},
            transferNotesLimit: 5,
            transferNotesHasNextPage: false,
            showTransferNoteModals: [],
            formValues: {
                transfer: ""
            },
            loading: 0
        };
    },
    computed: {
        ...mapGetters("auth", ["permissions"]),
        ...mapGetters("online", ["onLine"]),
        isAdmin() {
            return this.permissions === "admin";
        }
    },
    apollo: {
        allTransferNotes: {
            query: gql`
                query allTransferNotesGroupOverview($id: ID!, $limit: Int) {
                    allTransferNotes(groupId: $id, first: $limit) {
                        pageInfo {
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                notes
                                created
                                teacher {
                                    id
                                    fullName
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.groupId,
                    limit: this.transferNotesLimit
                };
            },
            update(data) {
                if (data.allTransferNotes) {
                    this.transferNotesHasNextPage =
                        data.allTransferNotes.pageInfo.hasNextPage;
                    return data.allTransferNotes.edges.map(x => x.node);
                } else {
                    return this.allTransferNotes;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van overdrachtsnotities."
                    ),
                    type: "error"
                });
            }
        },
        me: {
            query: gql`
                query meOverviewTransfers {
                    me {
                        id
                    }
                }
            `
        }
    },
    watch: {
        allTransferNotes() {
            this.showTransferNoteModals = this.allTransferNotes.map(
                () => false
            );
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        transferMutation(formValues) {
            const input = {
                group: atob(this.groupId).split(":")[1],
                notes: formValues.transfer
            };
            return {
                mutation: gql`
                    mutation transferNoteGroupOverview(
                        $input: TransferNoteMutationInput!
                    ) {
                        transferNote(input: $input) {
                            transferNote {
                                id
                                teacher {
                                    id
                                    fullName
                                }
                                notes
                                created
                            }
                            errors {
                                field
                                messages
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                },
                refetchQueries: [
                    {
                        query: gql`
                            query allTransferNotesGroupOverview(
                                $id: ID!
                                $limit: Int
                            ) {
                                allTransferNotes(groupId: $id, first: $limit) {
                                    pageInfo {
                                        hasNextPage
                                    }
                                    edges {
                                        node {
                                            id
                                            notes
                                            created
                                            teacher {
                                                id
                                                fullName
                                            }
                                        }
                                    }
                                }
                            }
                        `,
                        variables: {
                            id: this.groupId,
                            limit: this.transferNotesLimit
                        }
                    }
                ]
            };
        },
        formSuccess() {
            this.showMessage({
                message: this.$gettext("Overdracht is succesvol opgeslagen."),
                type: "success"
            });
        },
        showTransferNoteModal(index) {
            this.showTransferNoteModals = this.showTransferNoteModals.map(
                (value, i) => i === index
            );
        },
        confirmDeleteTransferNote(index) {
            const note = this.allTransferNotes[index];
            const numericId = atob(note.id).split(":")[1];

            if (
                confirm(
                    this.$gettext(
                        "Weet u zeker dat u deze overdracht wilt verwijderen?"
                    )
                )
            ) {
                this.loading++;
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation deleteTransferNote($id: ID!) {
                                deleteTransferNote(
                                    input: { transferNote: $id }
                                ) {
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        `,
                        variables: {
                            id: numericId
                        },
                        refetchQueries: [
                            {
                                query: gql`
                                    query allTransferNotesGroupOverview(
                                        $id: ID!
                                        $limit: Int
                                    ) {
                                        allTransferNotes(
                                            groupId: $id
                                            first: $limit
                                        ) {
                                            pageInfo {
                                                hasNextPage
                                            }
                                            edges {
                                                node {
                                                    id
                                                    notes
                                                    created
                                                    teacher {
                                                        id
                                                        fullName
                                                    }
                                                }
                                            }
                                        }
                                    }
                                `,
                                variables: {
                                    id: this.groupId,
                                    limit: this.transferNotesLimit
                                }
                            }
                        ]
                    })
                    .then(data => {
                        let errors = [];
                        for (const k in data.data) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    data.data,
                                    k
                                )
                            ) {
                                errors = errors.concat(
                                    data.data[k].errors || []
                                );
                            }
                        }

                        if (errors.length === 0) {
                            this.showMessage({
                                message: this.$gettext(
                                    "Overdracht is succesvol verwijderd."
                                ),
                                type: "success"
                            });
                        } else {
                            this.formError();
                        }
                    })
                    .catch(err => {
                        this.formError(err);
                    })
                    .finally(() => {
                        this.loading--;
                    });
            }
        }
    }
};
</script>
<style lang="sass">
.overview-notes
    display: flex
    flex-direction: column
    gap: 24px

.overview-note
    display: flex
    flex-direction: column
    padding: 16px

    border: 1px solid $color-blue-lighter
    background-color: $color-blue-lightest
    border-radius: 4px

.overview-note__info
    display: flex
    align-items: center
    gap: 12px

    @include font-size(14px, 24px)

.overview-note__footer
    display: flex
    justify-content: space-between
    flex-grow: 1
    margin-top: 12px
    padding-top: 12px

    border-top: 1px solid $color-blue-lighter
</style>
