<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppStudentCard",
    setup() {
        const { antlerClass } = antlerComponent("student-card");

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.student-card
    display: flex
    margin-bottom: $spacing-regular
    height: calc(100% - #{$spacing-regular})

    text-align: center

.student-card__link
    display: flex
    flex-flow: column nowrap
    align-items: center
    width: 100%
    padding: $spacing-regular

    border:
        width: 1px
        style: solid
        color: $color-blue-lighter

    border:
        color: transparent
        radius: 4px

    &:hover
        border-color: $color-blue-lighter
        box-shadow: inset 0 -5px 0 $color-blue-lighter

    &:active
        box-shadow: inset 0 -1px 0 $color-blue-lighter

.student-card__title
    @include font-size(18px)
    font:
        family: $secondary-font-family
        weight: $strong-weight

.student-card__image
    margin-bottom: $spacing-small
</style>
