var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TheLayout',{staticClass:"layout--vertical"},[_c('AppLayoutInner',{attrs:{"variant":"background"}},[_c('AppForm',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('AppPageHeader',{attrs:{"header-variant":"centered"}},[(_vm.groupSchedule.id)?_c('AppTitle',{staticClass:"subtitle header__title"},[_c('AppLink',{staticClass:"u-margin-right-small",attrs:{"to":{
                            name: 'group_schedule_detail',
                            params: {
                                date: _vm.$moment(_vm.groupSchedule.date).format(
                                    'DD-MM-YYYY'
                                )
                            }
                        }}},[_c('AppIcon',{staticClass:"u-margin-right-small u-rotate-90",attrs:{"name":"arrow","variant":"tiny"}}),_c('AppText',{directives:[{name:"translate",rawName:"v-translate",value:({
                                grade: _vm.groupSchedule.grade,
                                date: _vm.$moment(_vm.groupSchedule.date).format(
                                    'dddd LL'
                                )
                            }),expression:"{\n                                grade: groupSchedule.grade,\n                                date: $moment(groupSchedule.date).format(\n                                    'dddd LL'\n                                )\n                            }"}],attrs:{"variant":"date"}},[_vm._v(" %{date} - Groep %{grade} ")])],1)],1):_vm._e(),_c('AppButtonGroup',{staticClass:"u-margin-left-auto",attrs:{"gap":"large"}},[(!_vm.isScheduleFinal)?_c('AppButton',{attrs:{"variant":"link link-underline link-large link-bold link-font-primary","disabled":!_vm.onLine,"loading":_vm.loading > 0,"title":_vm.$gettext('Opslaan als concept'),"type":"submit"},on:{"click":function($event){_vm.scheduleStatus = 'draft'}}},[_c('translate',[_vm._v("Opslaan als concept")])],1):_vm._e(),_c('AppButton',{attrs:{"data-testid":"button","variant":"primary medium","disabled":!_vm.onLine || _vm.disableFinalize,"icon":"check-small","loading":_vm.loading > 0,"title":_vm.$gettext('Planning is compleet'),"type":"submit"},on:{"click":function($event){_vm.scheduleStatus = 'final'}}},[_c('translate',[_vm._v("Planning is compleet")])],1)],1)],1),(_vm.groupSchedule.schedules.length)?_c('AppScheduleGroupWrapper',[_c('AppScheduleGroup',{style:(("grid-template-columns: repeat(" + (_vm.groupSchedule.schedules.length) + ", minmax(calc(50% - 6px), 1fr))"))},_vm._l((_vm.groupSchedule.schedules),function(schedule){return _c('div',{key:schedule.id},[(schedule.grade)?_c('AppHeader',{attrs:{"variant":"small"}},[_c('translate',{staticClass:"subtitle-small",attrs:{"tag":"h3","translate-params":{
                                    grade: schedule.grade.grade
                                }}},[_vm._v(" Groep %{grade} ")])],1):_vm._e(),_c('AppScheduleUpdate',{ref:"schedules",refInFor:true,attrs:{"form-instance":_vm.$refs.form,"schedule-id":schedule.id,"schedule-status":_vm.scheduleStatus ||
                                    schedule.status.toLowerCase()},on:{"disable-finalize":function($event){_vm.disableFinalize = false}}})],1)}),0)],1):_vm._e()],1),_c('hr',{staticClass:"hr--large bg-blue-shade-light u-margin-bottom-small"}),_c('div',{staticClass:"t-align-right"},[_c('AppButton',{attrs:{"data-testid":"delete-button","variant":"link","disabled":!_vm.onLine,"loading":_vm.loading > 0},on:{"click":_vm.confirmDelete}},[_c('AppIcon',{staticClass:"u-margin-right-tiny",attrs:{"name":"trash"}}),_c('translate',[_vm._v("Dag verwijderen")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }