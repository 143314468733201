<template>
    <TheLayout class="layout--with-sidebar">
        <aside class="layout-side">
            <AppLayoutInner>
                <AppSection variant="side-menu">
                    <AppHeader class="section-header">
                        <AppTitle v-translate as="h3" class="section__title">
                            Planning
                        </AppTitle>
                    </AppHeader>

                    <AppMenuSide variant="small" class="u-margin-bottom">
                        <ul class="nav-menu__list">
                            <AppMenuSideItem
                                v-if="previousSchedule.id"
                                key="yesterday"
                            >
                                <AppMenuSideLink
                                    :to="{
                                        name: 'group_schedule_detail',
                                        params: {
                                            date: $moment(
                                                previousSchedule.date
                                            ).format('DD-MM-YYYY')
                                        }
                                    }"
                                    active-class="nav-side__link--active"
                                >
                                    <AppText class="text--date">
                                        {{ dateName(previousSchedule.date) }}
                                    </AppText>
                                </AppMenuSideLink>
                            </AppMenuSideItem>
                            <AppMenuSideItem
                                v-if="
                                    !futureSchedules[0] ||
                                        !$moment(
                                            futureSchedules[0].date
                                        ).isSame($moment(), 'day')
                                "
                                key="today"
                            >
                                <AppMenuSideLink
                                    v-translate
                                    :to="{
                                        name: 'group_schedule_detail',
                                        params: {
                                            date: $moment().format('DD-MM-YYYY')
                                        }
                                    }"
                                    active-class="nav-side__link--active"
                                >
                                    Vandaag
                                </AppMenuSideLink>
                            </AppMenuSideItem>
                            <AppMenuSideItem
                                v-for="schedule in futureSchedules"
                                :key="schedule.id"
                            >
                                <AppMenuSideLink
                                    :to="{
                                        name: 'group_schedule_detail',
                                        params: {
                                            date: $moment(schedule.date).format(
                                                'DD-MM-YYYY'
                                            )
                                        }
                                    }"
                                    active-class="nav-side__link--active"
                                >
                                    <AppText class="text--date">
                                        {{ dateName(schedule.date) }}
                                    </AppText>
                                </AppMenuSideLink>
                            </AppMenuSideItem>
                        </ul>
                    </AppMenuSide>

                    <AppInputDate
                        v-model="inputDate"
                        :highlighted-dates="highlightedDates"
                        :label="$gettext('Kies specifieke dag')"
                    />

                    <AppButton
                        variant="secondary medium-with-icon"
                        icon="plus"
                        @click="showModal = true"
                    >
                        <translate>Dag toevoegen</translate>
                    </AppButton>
                </AppSection>
            </AppLayoutInner>
        </aside>

        <AppLayoutMain layout-variant="background">
            <router-view />
        </AppLayoutMain>

        <AppScheduleCreateModal
            v-model="showModal"
            :group-id="groupId"
            @created="groupScheduleCreated"
        />
    </TheLayout>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppHeader from "@/components/AppHeader.vue";
import AppInputDate from "@/components/AppInputDate";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppLayoutMain from "@/components/AppLayoutMain.vue";
import AppMenuSide from "@/components/AppMenuSide.vue";
import AppMenuSideItem from "@/components/AppMenuSideItem.vue";
import AppMenuSideLink from "@/components/AppMenuSideLink.vue";
import AppScheduleCreateModal from "@/components/AppScheduleCreateModal";
import AppSection from "@/components/AppSection.vue";
import AppText from "@/components/AppText.vue";
import AppTitle from "@/components/AppTitle.vue";
import TheLayout from "@/components/TheLayoutDefault";
import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    name: "GroupSchedule",
    components: {
        AppLayoutMain,
        AppMenuSideLink,
        AppMenuSideItem,
        AppMenuSide,
        AppTitle,
        AppHeader,
        AppLayoutInner,
        AppText,
        AppSection,
        AppScheduleCreateModal,
        AppButton,
        AppInputDate,
        TheLayout
    },
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allGroupSchedules: [],
            internalInputDate: "",
            loading: 0,
            showModal: false
        };
    },
    computed: {
        futureSchedules() {
            const now = this.$moment();
            return this.allGroupSchedules
                .filter(x => this.$moment(x.date).isSameOrAfter(now, "day"))
                .slice(0, 10);
        },
        previousSchedule() {
            const now = this.$moment();
            const schedules = this.allGroupSchedules.filter(x =>
                this.$moment(x.date).isBefore(now, "day")
            );
            return schedules.length ? schedules[schedules.length - 1] : {};
        },
        highlightedDates() {
            return {
                dates: this.allGroupSchedules.map(x =>
                    this.$moment(x.date).toDate()
                )
            };
        },
        inputDate: {
            get() {
                return this.internalInputDate;
            },
            set(newValue) {
                this.internalInputDate = newValue;
                // Dirty hack to keep date input empty
                this.$nextTick(() => {
                    this.internalInputDate = "";
                });
                this.$router.push({
                    name: "group_schedule_detail",
                    params: {
                        date: this.$moment(newValue).format("DD-MM-YYYY")
                    }
                });
            }
        }
    },
    apollo: {
        allGroupSchedules: {
            query: gql`
                query allGroupSchedulesGroupSchedule($groupId: ID!) {
                    allGroupSchedules(groupId: $groupId) {
                        edges {
                            node {
                                id
                                date
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    groupId: this.groupId
                };
            },
            update(data) {
                if (data.allGroupSchedules) {
                    return data.allGroupSchedules.edges.map(x => x.node);
                } else {
                    return this.allGroupSchedules;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de planning."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        dateName(date) {
            date = this.$moment(date);
            if (date.isSame(this.$moment(), "day")) {
                return this.$gettext("Vandaag");
            } else if (date.isSame(this.$moment().subtract(1, "day"), "day")) {
                return this.$gettext("Gisteren");
            } else if (date.isSame(this.$moment().add(1, "day"), "day")) {
                return this.$gettext("Morgen");
            } else {
                return date.format("dddd LL");
            }
        },
        groupScheduleCreated(groupScheduleId) {
            this.showModal = false;
            this.$nextTick(() => {
                this.$router.push({
                    name: "group_schedule_update",
                    params: {
                        groupScheduleId
                    }
                });
            });
        }
    }
};
</script>
