<template>
    <TheLayout :class="{ 'layout--with-sidebar': reportNumbers.length > 0 }">
        <aside v-if="reportNumbers.length > 0" class="layout-side">
            <AppLayoutInner>
                <AppSection variant="side-menu">
                    <AppHeader class="header section-header">
                        <AppTitle v-translate class="section-header__title">
                            Rapporten
                        </AppTitle>
                    </AppHeader>

                    <AppMenuSide>
                        <ul class="nav-menu__list">
                            <AppMenuSideItem
                                v-for="number in reportNumbers"
                                :key="number"
                            >
                                <AppMenuSideLink
                                    :to="{
                                        name: 'group_report_detail',
                                        params: { number }
                                    }"
                                    class="nav-menu__link nav-side__link"
                                    exact-active-class="nav-side__link--active"
                                >
                                    <translate :translate-params="{ number }">
                                        Rapport %{number}
                                    </translate>
                                </AppMenuSideLink>
                            </AppMenuSideItem>
                        </ul>
                    </AppMenuSide>
                    <AppButton
                        variant="secondary medium-with-icon"
                        icon="plus"
                        :disabled="!onLine || hasDrafts"
                        :loading="loading > 0"
                        @click="generateReports"
                    >
                        <translate>Genereer rapporten</translate>
                    </AppButton>
                </AppSection>
            </AppLayoutInner>
        </aside>
        <AppLayoutMain>
            <router-view />
        </AppLayoutMain>
    </TheLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppHeader from "@/components/AppHeader.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppLayoutMain from "@/components/AppLayoutMain.vue";
import AppMenuSide from "@/components/AppMenuSide.vue";
import AppMenuSideItem from "@/components/AppMenuSideItem.vue";
import AppMenuSideLink from "@/components/AppMenuSideLink.vue";
import AppSection from "@/components/AppSection.vue";
import AppTitle from "@/components/AppTitle.vue";
import GenerateReports from "@/mixins/GenerateReports";
import TheLayout from "@/components/TheLayoutDefault";
import { consoleError } from "@/console";
import gql from "graphql-tag";

export default {
    name: "GroupReport",
    components: {
        AppLayoutMain,
        AppMenuSideLink,
        AppMenuSideItem,
        AppMenuSide,
        AppTitle,
        AppHeader,
        AppLayoutInner,
        AppSection,
        AppButton,
        TheLayout
    },
    mixins: [GenerateReports],
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allReports: [],
            loading: 0,
            reportNumber: null
        };
    },
    computed: {
        ...mapGetters("online", ["onLine"]),
        hasDrafts() {
            return this.allReports.some(node => node.status === "DRAFT");
        },
        reportNumbers() {
            return [
                ...this.allReports.reduce((acc, node) => {
                    acc.add(node.number);
                    return acc;
                }, new Set())
            ].sort((a, b) => (b > a ? 1 : -1));
        }
    },
    apollo: {
        allReports: {
            query: gql`
                query allReportsGroupReport($groupId: ID!) {
                    allReports(groupId: $groupId) {
                        edges {
                            node {
                                id
                                number
                                status
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    groupId: this.groupId
                };
            },
            update(data) {
                if (data.allReports) {
                    return data.allReports.edges.map(x => x.node);
                } else {
                    return this.allReports;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van rapporten."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: mapActions("message", ["showMessage"])
};
</script>
