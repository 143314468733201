<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppCardBody",
    setup() {
        const { antlerClass } = antlerComponent("card__body");

        return {
            antlerClass
        };
    }
});
</script>
