<template>
    <footer :class="antlerClass()">
        <slot />
    </footer>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppCardFooter",
    setup() {
        const { antlerClass } = antlerComponent("card__footer");

        return {
            antlerClass
        };
    }
});
</script>
