<template>
    <div>
        <template v-if="formValues.documents.length > 0">
            <AppForm ref="form" lazy-validation @submit.prevent="formSubmit">
                <AppButtonGroup class="u-margin-bottom" variant="between">
                    <AppButton
                        variant="primary medium-with-icon"
                        icon="plus"
                        @click.prevent="addDocument"
                    >
                        <translate>Document toevoegen</translate>
                    </AppButton>

                    <AppButton
                        variant="secondary medium-with-icon"
                        :disabled="!onLine"
                        icon="check-small"
                        :loading="loading > 0"
                        :title="$gettext('Opslaan')"
                        type="submit"
                    >
                        <translate>Opslaan</translate>
                    </AppButton>
                </AppButtonGroup>

                <AppContent
                    v-for="document in formValues.documents"
                    :key="document.index"
                    variant="inline-form"
                >
                    <AppFormGroup
                        v-if="!document.id"
                        variant="hide-messages inline"
                    >
                        <AppInputText
                            v-model="document.name"
                            :disabled="loading > 0"
                            :label="$gettext('Naam')"
                            :required="!!document.document"
                            :error-messages="
                                formErrors.documents.forms[document.index] &&
                                    formErrors.documents.forms[document.index]
                                        .name
                            "
                            type="text"
                        />
                        <AppInputFile
                            v-model="document.document"
                            :disabled="loading > 0"
                            :label="$gettext('Document')"
                            :required="!!document.name"
                            :error-messages="
                                formErrors.documents.forms[document.index] &&
                                    formErrors.documents.forms[document.index]
                                        .document
                            "
                        />
                    </AppFormGroup>
                    <div v-else>
                        <AppInputFile
                            v-model="document.document"
                            clearable
                            :disabled="loading > 0"
                            hide-input-on-value
                        />
                    </div>
                </AppContent>
            </AppForm>
        </template>
        <template v-else-if="!loading">
            <AppOverviewPlaceholder
                variant="fixed fixed-small text-small background"
                class="u-margin-horizontal-auto"
            >
                <p v-translate>
                    Voeg (pdf, word, excel) documenten toe aan deze groep zodat
                    deze centraal beschikbaar zijn.
                </p>
                <AppButton
                    slot="button"
                    variant="icon circle shadow"
                    class="u-margin-left-small"
                    icon="plus"
                    icon-size="tiny"
                    @click.prevent="addDocument"
                />
            </AppOverviewPlaceholder>
        </template>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppButtonGroup from "@/components/AppButtonGroup.vue";
import AppContent from "@/components/AppContent";
import AppForm from "@/components/AppForm";
import AppFormGroup from "@/components/AppFormGroup";
import AppInputFile from "@/components/AppInputFile";
import AppInputText from "@/components/AppInputText";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import FormSave from "@/mixins/FormSave";
import gql from "graphql-tag";

export default {
    name: "GroupOverviewDocuments",
    components: {
        AppButtonGroup,
        AppContent,
        AppButton,
        AppOverviewPlaceholder,
        AppForm,
        AppFormGroup,
        AppInputText,
        AppInputFile
    },
    mixins: [FormSave],
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: 0,
            group: {
                documents: []
            },
            formValues: {
                documents: []
            },
            emptyDocument: {
                id: "",
                name: "",
                document: null,
                index: null,
                delete: false
            },
            emptyFormErrors: {
                documents: {
                    nonFormErrors: [],
                    forms: []
                }
            }
        };
    },
    computed: mapGetters("online", ["onLine"]),
    apollo: {
        group: {
            query: gql`
                query($id: ID!) {
                    group(id: $id) {
                        id
                        documents {
                            edges {
                                node {
                                    id
                                    name
                                    document {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.groupId
                };
            },
            update(data) {
                if (data.group) {
                    const group = { ...data.group };
                    group.documents = group.documents.edges.map((x, i) => {
                        return {
                            index: i,
                            id: atob(x.node.id).split(":")[1],
                            name: x.node.name,
                            document: {
                                ...x.node.document,
                                filename: x.node.name,
                                name: x.node.name
                            },
                            delete: false
                        };
                    });
                    this.setFormValues(group);
                    return group;
                } else {
                    return this.group;
                }
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        addDocument() {
            this.formValues.documents.unshift({
                ...this.emptyDocument,
                index: this.formValues.documents.length
            });
        },
        getMutation() {
            const documents = this.formValues.documents.sort(
                (a, b) => a.index - b.index
            );

            const input = {
                id: atob(this.groupId).split(":")[1],
                documentsTotalForms: this.formValues.documents.length,
                documentsInitialForms: this.group.documents.length,
                documents: documents.map(val => {
                    return {
                        id: val.id,
                        name: val.name,
                        document: { file: val.document },
                        delete: !!(val.document && val.document.clear)
                    };
                })
            };

            return {
                mutation: gql`
                    mutation groupOverviewDocuments(
                        $input: GroupMutationInput!
                    ) {
                        group(input: $input) {
                            group {
                                id
                                documents {
                                    edges {
                                        node {
                                            id
                                            name
                                            document {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                            errors {
                                ... on ErrorType {
                                    field
                                    messages
                                }
                                ... on ErrorTypeFormset {
                                    field
                                    formIndex
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            };
        },
        formSuccess() {
            this.showMessage({
                message: this.$gettext(
                    "Wijzigingen zijn succesvol opgeslagen."
                ),
                type: "success"
            });
        }
    }
};
</script>
