<template>
    <AppPage variant="no-padding-top">
        <AppLayout variant="vertical max-height">
            <AppWrapper variant="overflow">
                <AppOverviewList variant="hover sticky group">
                    <thead
                        class="overview-list-head overview-list-head--rotated"
                    >
                        <tr>
                            <th
                                class="overview-list__column overview-list__column--name"
                            >
                                <span class="overview-list-head__spacer" />
                            </th>
                            <th
                                v-for="level in allEducationalLevels[
                                    [sortedKeys[0]]
                                ]"
                                :key="level.subject.id"
                                class="overview-list__column overview-list__column--grade"
                                width="60px"
                            >
                                <div class="overview-list-head__name">
                                    <div class="overview-list-head__name-text">
                                        {{ level.subject.name }}
                                    </div>
                                </div>
                            </th>
                            <th
                                class="overview-list__column overview-list__column--fill"
                            >
                                <span class="overview-list-head__spacer" />
                            </th>
                        </tr>
                    </thead>
                    <tbody class="overview-list-body">
                        <tr
                            v-for="key in sortedKeys"
                            :key="key"
                            class="overview-list__row"
                        >
                            <td
                                class="overview-list__column overview-list__column--name"
                            >
                                <router-link
                                    :key="
                                        allEducationalLevels[key][0].student.id
                                    "
                                    :to="{
                                        name: 'student_insight',
                                        params: {
                                            id:
                                                allEducationalLevels[key][0]
                                                    .student.id
                                        }
                                    }"
                                >
                                    {{
                                        allEducationalLevels[key][0].student
                                            .fullName
                                    }}
                                </router-link>
                            </td>
                            <td
                                v-for="level in allEducationalLevels[key]"
                                :key="level.id"
                                class="overview-list__column overview-list__column--grade"
                                width="60px"
                            >
                                <AppInputEducationalLevel
                                    v-model="levelValues[level.id]"
                                    :grade="grade"
                                    :disabled="
                                        loading > 0 || !onLine || level.readonly
                                    "
                                    @change="changeLevel(level.id, $event)"
                                />
                            </td>
                            <td
                                class="overview-list__column overview-list__column--fill"
                            />
                        </tr>
                    </tbody>
                </AppOverviewList>
            </AppWrapper>
        </AppLayout>
    </AppPage>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppInputEducationalLevel from "@/components/AppInputEducationalLevel";
import AppLayout from "@/components/AppLayout.vue";
import AppOverviewList from "@/components/AppOverviewList.vue";
import AppPage from "@/components/AppPage.vue";
import AppWrapper from "@/components/AppWrapper.vue";
import ChangeLevel from "@/mixins/ChangeLevel";
import { consoleError } from "@/console";
import gql from "graphql-tag";

export default {
    name: "LevelDetail",
    components: {
        AppOverviewList,
        AppWrapper,
        AppLayout,
        AppPage,
        AppInputEducationalLevel
    },
    mixins: [ChangeLevel],
    props: {
        grade: {
            type: Number,
            required: true
        },
        groupId: {
            type: String,
            required: true
        },
        subjectId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allEducationalLevels: {},
            levelValues: {},
            subject: {}
        };
    },
    computed: {
        ...mapGetters("online", ["onLine"]),
        sortedKeys() {
            return Object.keys(this.allEducationalLevels).sort();
        }
    },
    apollo: {
        allEducationalLevels: {
            query: gql`
                query allEducationalLevelsLevelDetail(
                    $groupId: ID!
                    $subjectId: ID!
                    $grade: Int!
                ) {
                    allEducationalLevels(
                        groupId: $groupId
                        subjectId: $subjectId
                        grade: $grade
                    ) {
                        edges {
                            node {
                                id
                                level
                                readonly
                                student {
                                    id
                                    fullName
                                    lastName
                                }
                                subject {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    groupId: this.groupId,
                    subjectId: this.subjectId,
                    grade: this.grade
                };
            },
            update(data) {
                if (data.allEducationalLevels) {
                    const levels = data.allEducationalLevels.edges.map(
                        x => x.node
                    );

                    for (const level of levels) {
                        this.levelValues[level.id] = level.level;
                    }

                    return levels.reduce((acc, obj) => {
                        const uniqueKey = `${obj.student.lastName}-${obj.student.id}`;
                        if (!acc[uniqueKey]) acc[uniqueKey] = [];
                        acc[uniqueKey].push(obj);
                        return acc;
                    }, {});
                } else {
                    return this.allEducationalLevels;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van onderwijsniveaus."
                    ),
                    type: "error"
                });
            }
        },
        subject: {
            query: gql`
                query subjectLevelDetail($id: ID!) {
                    subject(id: $id) {
                        id
                        name
                    }
                }
            `,
            variables() {
                return {
                    id: this.subjectId
                };
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van onderwijsniveaus."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: mapActions("message", ["showMessage"])
};
</script>
