import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: 0
        };
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        generateReports() {
            this.loading++;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation generateReportsReportDetail($groupId: ID!) {
                            generateReports(groupId: $groupId) {
                                reports {
                                    id
                                    number
                                }
                            }
                        }
                    `,
                    variables: {
                        groupId: this.groupId
                    },
                    refetchQueries: [
                        {
                            query: gql`
                                query($groupId: ID!) {
                                    allReports(groupId: $groupId) {
                                        edges {
                                            node {
                                                id
                                                number
                                                status
                                            }
                                        }
                                    }
                                }
                            `,
                            variables: { groupId: this.groupId }
                        }
                    ]
                })
                .then(data => {
                    if (
                        data.data.generateReports &&
                        data.data.generateReports.reports.length
                    ) {
                        this.$router.push({
                            name: "group_report_detail",
                            params: {
                                number:
                                    data.data.generateReports.reports[0].number
                            }
                        });
                        this.showMessage({
                            message: this.$gettext(
                                "Rapporten zijn succesvol gegenereerd."
                            ),
                            type: "success"
                        });
                    } else {
                        throw new Error("Failed to generate reports.");
                    }
                })
                .catch(err => {
                    consoleError(err);
                    this.showMessage({
                        message: this.$gettext(
                            "Er is een fout opgetreden tijdens het genereren, probeer het a.u.b. opnieuw."
                        ),
                        type: "error"
                    });
                })
                .finally(() => {
                    this.loading--;
                });
        }
    }
};
