var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppScheduleFormItemGroup',{on:{"addScheduleRow":_vm.addScheduleRow}},[_c('Draggable',{attrs:{"disabled":_vm.loading > 0,"handle":".button--draggable"},on:{"change":_vm.changeSchedule},model:{value:(_vm.formValues.scheduleRows),callback:function ($$v) {_vm.$set(_vm.formValues, "scheduleRows", $$v)},expression:"formValues.scheduleRows"}},_vm._l((_vm.formValues.scheduleRows),function(row){return _c('AppScheduleFormItem',{key:((row.index) + "-" + (row.id)),attrs:{"context":"schedule","delete-item":row.delete,"disabled":_vm.loading > 0,"enable-end-time":!_vm.lastNotDeleted || row.index === _vm.lastNotDeleted.index,"end-time":row.endTime,"form-index":row.index,"form-errors":_vm.formErrors.scheduleRows.forms,"grades":_vm.schedule.grade
                    ? [_vm.schedule.grade.grade]
                    : _vm.schedule.groupSchedule.group.grades,"notes":row.notes,"theme":row.theme,"purpose":row.purpose,"start-time":row.startTime,"subject-description":row.subjectDescription,"subject-id":row.subjectId,"sub-subject-id":row.subSubjectId,"is-bundle":row.isBundle,"bundle-rows":row.bundleRows,"is-final":_vm.scheduleStatus === 'final'},on:{"update:notes":function($event){return _vm.$set(row, "notes", $event)},"update:theme":function($event){return _vm.$set(row, "theme", $event)},"update:purpose":function($event){return _vm.$set(row, "purpose", $event)},"update:subjectDescription":function($event){return _vm.$set(row, "subjectDescription", $event)},"update:subject-description":function($event){return _vm.$set(row, "subjectDescription", $event)},"update:subSubjectId":function($event){return _vm.$set(row, "subSubjectId", $event)},"update:sub-subject-id":function($event){return _vm.$set(row, "subSubjectId", $event)},"update:startTime":function($event){row.startTime = $event;
                _vm.updateEndTimes();
                _vm.updateBundleRows();},"update:endTime":function($event){row.endTime = $event;
                _vm.updateBundleRows();},"update:subjectId":function($event){row.subjectId = $event;
                _vm.updateBundleRows();},"update:isBundle":function($event){row.isBundle = $event;
                _vm.updateBundleRows();},"update:deleteItem":function($event){row.delete = $event;
                _vm.updateEndTimes();
                _vm.updateBundleRows();},"addScheduleBundleRow":function($event){return _vm.addScheduleBundleRow(row)}}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }