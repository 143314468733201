<template>
    <TheLayout>
        <AppLayout v-if="!loading" variant="vertical">
            <AppHeader>
                <AppTitle v-translate>
                    Leerlingen
                </AppTitle>
            </AppHeader>

            <AppRow variant="no-gutters">
                <AppColumn
                    v-for="student in group.students"
                    :key="student.id"
                    class="column-6 column-2--lte-tablet column-4--lte-tablet-portrait"
                >
                    <AppStudentCard>
                        <router-link
                            :to="{
                                name: 'student_insight',
                                params: { id: student.id }
                            }"
                            class="student-card__link"
                        >
                            <AppAvatar
                                class="student-card__image"
                                :image="student.profilePicture.url || undefined"
                                size="huge"
                            />
                            <p class="student-card__title">
                                {{ student.fullName }}
                            </p>
                        </router-link>
                    </AppStudentCard>
                </AppColumn>
            </AppRow>
        </AppLayout>
    </TheLayout>
</template>

<script>
import AppAvatar from "@/components/AppAvatar";
import AppColumn from "@/components/AppColumn.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppLayout from "@/components/AppLayout.vue";
import AppRow from "@/components/AppRow.vue";
import AppStudentCard from "@/components/AppStudentCard.vue";
import AppTitle from "@/components/AppTitle.vue";
import TheLayout from "@/components/TheLayoutPage";

import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    name: "GroupStudents",
    components: {
        AppStudentCard,
        AppColumn,
        AppRow,
        AppLayout,
        AppTitle,
        AppHeader,
        AppAvatar,
        TheLayout
    },
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: 0,
            group: {
                students: []
            }
        };
    },
    apollo: {
        group: {
            // Todo: Refactor query. Query on students with group filter.
            query: gql`
                query groupStudents($id: ID!) {
                    group(id: $id) {
                        id
                        students {
                            edges {
                                node {
                                    id
                                    fullName
                                    profilePicture {
                                        url(
                                            options: {
                                                geometry: "400x400"
                                                asynchronous: true
                                                crop: "center"
                                            }
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.groupId
                };
            },
            update(data) {
                if (data.group) {
                    const group = { ...data.group };
                    group.students = group.students.edges.map(x => x.node);
                    return group;
                } else {
                    return this.group;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van leerlingen."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: mapActions("message", ["showMessage"])
};
</script>
