<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppCard",
    setup() {
        const { antlerClass } = antlerComponent("card");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.card
    display: flex
    flex-direction: column

    width: 100%

    background-color: #fff
    box-shadow: 1px 1px 2px rgba(#000, .16)
    border-radius: $global-border-radius

.card__body
    padding: $spacing-regular

.card__footer
    display: flex
    justify-content: space-between
    padding: 20px $spacing-regular

    background-color: $color-blue-lightest
    border-bottom-left-radius: $global-border-radius
    border-bottom-right-radius: $global-border-radius
</style>
