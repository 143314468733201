var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TheLayout',{staticClass:"layout--with-sidebar"},[_c('aside',{staticClass:"layout-side"},[_c('AppLayoutInner',[_c('AppSection',{attrs:{"variant":"side-menu"}},[_c('AppHeader',{staticClass:"section-header"},[_c('AppTitle',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"section__title",attrs:{"as":"h3"}},[_vm._v(" Planning ")])],1),_c('AppMenuSide',{staticClass:"u-margin-bottom",attrs:{"variant":"small"}},[_c('ul',{staticClass:"nav-menu__list"},[(_vm.previousSchedule.id)?_c('AppMenuSideItem',{key:"yesterday"},[_c('AppMenuSideLink',{attrs:{"to":{
                                    name: 'group_schedule_detail',
                                    params: {
                                        date: _vm.$moment(
                                            _vm.previousSchedule.date
                                        ).format('DD-MM-YYYY')
                                    }
                                },"active-class":"nav-side__link--active"}},[_c('AppText',{staticClass:"text--date"},[_vm._v(" "+_vm._s(_vm.dateName(_vm.previousSchedule.date))+" ")])],1)],1):_vm._e(),(
                                !_vm.futureSchedules[0] ||
                                    !_vm.$moment(
                                        _vm.futureSchedules[0].date
                                    ).isSame(_vm.$moment(), 'day')
                            )?_c('AppMenuSideItem',{key:"today"},[_c('AppMenuSideLink',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"to":{
                                    name: 'group_schedule_detail',
                                    params: {
                                        date: _vm.$moment().format('DD-MM-YYYY')
                                    }
                                },"active-class":"nav-side__link--active"}},[_vm._v(" Vandaag ")])],1):_vm._e(),_vm._l((_vm.futureSchedules),function(schedule){return _c('AppMenuSideItem',{key:schedule.id},[_c('AppMenuSideLink',{attrs:{"to":{
                                    name: 'group_schedule_detail',
                                    params: {
                                        date: _vm.$moment(schedule.date).format(
                                            'DD-MM-YYYY'
                                        )
                                    }
                                },"active-class":"nav-side__link--active"}},[_c('AppText',{staticClass:"text--date"},[_vm._v(" "+_vm._s(_vm.dateName(schedule.date))+" ")])],1)],1)})],2)]),_c('AppInputDate',{attrs:{"highlighted-dates":_vm.highlightedDates,"label":_vm.$gettext('Kies specifieke dag')},model:{value:(_vm.inputDate),callback:function ($$v) {_vm.inputDate=$$v},expression:"inputDate"}}),_c('AppButton',{attrs:{"variant":"secondary medium-with-icon","icon":"plus"},on:{"click":function($event){_vm.showModal = true}}},[_c('translate',[_vm._v("Dag toevoegen")])],1)],1)],1)],1),_c('AppLayoutMain',{attrs:{"layout-variant":"background"}},[_c('router-view')],1),_c('AppScheduleCreateModal',{attrs:{"group-id":_vm.groupId},on:{"created":_vm.groupScheduleCreated},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }