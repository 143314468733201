var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppPage',{attrs:{"variant":"no-padding-top"}},[_c('AppLayout',{attrs:{"variant":"vertical max-height"}},[_c('AppWrapper',{attrs:{"variant":"overflow"}},[_c('AppOverviewList',{attrs:{"variant":"hover sticky group"}},[_c('thead',{staticClass:"overview-list-head overview-list-head--rotated"},[_c('tr',[_c('th',{staticClass:"overview-list__column overview-list__column--name"},[_c('span',{staticClass:"overview-list-head__spacer"})]),_vm._l((_vm.allEducationalLevels[
                                [_vm.sortedKeys[0]]
                            ]),function(level){return _c('th',{key:level.subject.id,staticClass:"overview-list__column overview-list__column--grade",attrs:{"width":"60px"}},[_c('div',{staticClass:"overview-list-head__name"},[_c('div',{staticClass:"overview-list-head__name-text"},[_vm._v(" "+_vm._s(level.subject.name)+" ")])])])}),_c('th',{staticClass:"overview-list__column overview-list__column--fill"},[_c('span',{staticClass:"overview-list-head__spacer"})])],2)]),_c('tbody',{staticClass:"overview-list-body"},_vm._l((_vm.sortedKeys),function(key){return _c('tr',{key:key,staticClass:"overview-list__row"},[_c('td',{staticClass:"overview-list__column overview-list__column--name"},[_c('router-link',{key:_vm.allEducationalLevels[key][0].student.id,attrs:{"to":{
                                    name: 'student_insight',
                                    params: {
                                        id:
                                            _vm.allEducationalLevels[key][0]
                                                .student.id
                                    }
                                }}},[_vm._v(" "+_vm._s(_vm.allEducationalLevels[key][0].student .fullName)+" ")])],1),_vm._l((_vm.allEducationalLevels[key]),function(level){return _c('td',{key:level.id,staticClass:"overview-list__column overview-list__column--grade",attrs:{"width":"60px"}},[_c('AppInputEducationalLevel',{attrs:{"grade":_vm.grade,"disabled":_vm.loading > 0 || !_vm.onLine || level.readonly},on:{"change":function($event){return _vm.changeLevel(level.id, $event)}},model:{value:(_vm.levelValues[level.id]),callback:function ($$v) {_vm.$set(_vm.levelValues, level.id, $$v)},expression:"levelValues[level.id]"}})],1)}),_c('td',{staticClass:"overview-list__column overview-list__column--fill"})],2)}),0)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }