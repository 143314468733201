<template>
    <div :class="antlerClass()">
        <AppHeader v-if="title">
            <AppTitle size="xsmall" as="h2">
                {{ title }}
            </AppTitle>
        </AppHeader>

        <AppEducationPlan
            v-for="educationPlan in educationPlans"
            :key="educationPlan.id"
            :education-plan-id="educationPlan.id"
            :goal="educationPlan.goal"
            :method="educationPlan.method"
            :is-expired="!!educationPlan.expired"
            :students="educationPlan.students"
            :bulk-id="educationPlan.bulkId"
            :group-id="educationPlan.group ? educationPlan.group.id : ''"
        />
    </div>
</template>

<script>
import AppEducationPlan from "@/components/AppEducationPlan";
import AppHeader from "@/components/AppHeader";
import AppTitle from "@/components/AppTitle";
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppEducationPlanGroup",
    components: { AppEducationPlan, AppTitle, AppHeader },
    props: {
        title: {
            type: String,
            default: ""
        },
        educationPlans: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("education-plan-group", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.education-plan-group
    & + .education-plan-group
        margin-top: $spacing-large

    .education-plan
        margin-bottom: $spacing-regular

        &:last-child
            margin-bottom: 0
</style>
