<template>
    <TheLayoutDefault class="layout--with-sidebar">
        <aside class="layout-side">
            <AppLayoutInner>
                <AppSection variant="side-menu">
                    <AppHeader>
                        <AppTitle v-translate as="h3">
                            Ontwikkeling
                        </AppTitle>
                    </AppHeader>

                    <AppMenuSide variant="small">
                        <AppMenuSideItem
                            v-for="subject in allEducationPlanSubjects"
                            :key="subject.id"
                        >
                            <AppMenuSideLink
                                :to="{
                                    name: 'group_education_plans_detail',
                                    params: {
                                        subject: subject.id
                                    }
                                }"
                                active-class="nav-side__link--active"
                            >
                                {{ subject.name }}
                            </AppMenuSideLink>
                        </AppMenuSideItem>
                    </AppMenuSide>
                </AppSection>
            </AppLayoutInner>
        </aside>

        <AppLayoutMain layout-variant="background">
            <router-view />
        </AppLayoutMain>
    </TheLayoutDefault>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppHeader from "@/components/AppHeader";
import AppLayoutInner from "@/components/AppLayoutInner";
import AppLayoutMain from "@/components/AppLayoutMain";
import AppMenuSide from "@/components/AppMenuSide";
import AppMenuSideItem from "@/components/AppMenuSideItem";
import AppMenuSideLink from "@/components/AppMenuSideLink";
import AppSection from "@/components/AppSection";
import AppTitle from "@/components/AppTitle.vue";
import TheLayoutDefault from "@/components/TheLayoutDefault";
import { consoleError } from "@/console";
import gql from "graphql-tag";

export default {
    name: "GroupEducationalPlans",
    components: {
        AppTitle,
        AppLayoutMain,
        AppLayoutInner,
        AppSection,
        AppHeader,
        AppMenuSideLink,
        AppMenuSideItem,
        AppMenuSide,
        TheLayoutDefault
    },
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allEducationPlanSubjects: {},
            loading: 0
        };
    },
    computed: {
        ...mapGetters("insightmodal", [
            "createdInsights",
            "updatedInsights",
            "expiredInsights",
            "deletedInsights"
        ])
    },
    apollo: {
        allEducationPlanSubjects: {
            query: gql`
                query allEducationPlanSubjectsQuery($id: ID!) {
                    allEducationPlanSubjects(groupId: $id) {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.groupId
                };
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de ontwikkelplan-vakken."
                    ),
                    type: "error"
                });
            },
            update(data) {
                if (data.allEducationPlanSubjects) {
                    return data.allEducationPlanSubjects.edges.map(x => x.node);
                } else {
                    return this.allEducationPlanSubjects;
                }
            }
        }
    },
    watch: {
        createdInsights() {
            const lastCreatedInsightId = [...this.createdInsights].pop();
            this.redirectToEducationPlanSubjectByInsightId(
                lastCreatedInsightId
            );

            this.refetchEducationPlanSubjects();
        },
        expiredInsights() {
            this.refetchEducationPlanSubjects();
        },
        updatedInsights() {
            this.refetchEducationPlanSubjects();
        },
        deletedInsights() {
            this.refetchEducationPlanSubjects();
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        refetchEducationPlanSubjects() {
            this.$apollo.queries.allEducationPlanSubjects.refetch();
        },
        redirectToEducationPlanSubjectByInsightId(insightId) {
            this.$apollo
                .query({
                    query: gql`
                        query($id: ID!) {
                            insight(id: $id) {
                                id
                                type
                                educationPlanSubject {
                                    id
                                    name
                                    parent {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        id: insightId
                    }
                })
                .then(response => {
                    if (
                        response.data &&
                        response.data.insight &&
                        response.data.insight.type === "EDUCATION_PLAN" &&
                        response.data.insight.educationPlanSubject
                    ) {
                        this.$router.push({
                            name: "group_education_plans_detail",
                            params: {
                                subject: response.data.insight
                                    .educationPlanSubject.parent
                                    ? response.data.insight.educationPlanSubject
                                          .parent.id
                                    : response.data.insight.educationPlanSubject
                                          .id
                            }
                        });
                    }
                });
        }
    }
};
</script>
