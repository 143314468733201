<template>
    <AppCard :class="antlerClass()">
        <AppCardBody>
            <AppRow>
                <AppColumn class="m:column--6/12">
                    <AppSection>
                        <AppHeader size="small">
                            <AppTitle
                                v-translate
                                as="h3"
                                font="secondary"
                                size="xxsmall"
                            >
                                Doel
                            </AppTitle>
                        </AppHeader>
                        <AppArticle>
                            {{ goal }}
                        </AppArticle>
                    </AppSection>
                </AppColumn>

                <AppColumn class="m:column--6/12">
                    <AppSection>
                        <AppHeader size="small">
                            <AppTitle
                                v-translate
                                as="h3"
                                font="secondary"
                                size="xxsmall"
                            >
                                Werkwijze
                            </AppTitle>
                        </AppHeader>
                        <AppArticle>
                            {{ method }}
                        </AppArticle>
                    </AppSection>
                </AppColumn>
            </AppRow>

            <AppDivider />

            <AppStudentLinks v-if="students">
                <AppStudentLink
                    v-for="student in students"
                    :key="student.id"
                    :student-id="student.id"
                    :student-name="student.fullName"
                />
            </AppStudentLinks>
        </AppCardBody>

        <AppCardFooter>
            <AppText v-if="isExpired" v-translate as="span" size="small">
                Afgerond
            </AppText>
            <AppButton
                v-translate
                variant="clean"
                :class="{ 'u-margin-left': isExpired }"
                icon="copy"
                @click="copyInsight"
            >
                Maak kopie
            </AppButton>

            <AppButton
                icon="eye"
                icon-size="regular"
                variant="icon-clean"
                class="u-margin-left-auto"
                @click="handleInsightModalClick"
            />

            <AppInsightDetailModal
                v-model="showDetailModal"
                :insight-id="educationPlanId"
            />

            <AppEditMultipleEducationPlanStudentsModal
                v-if="bulkId"
                v-model="showMultiInsightModal"
                :bulk-id="bulkId"
                :group-id="groupId"
                :is-expired="isExpired"
            />
        </AppCardFooter>
    </AppCard>
</template>

<script>
import AppArticle from "@/components/AppArticle";
import AppButton from "@/components/AppButton";
import AppCard from "@/components/AppCard";
import AppCardBody from "@/components/AppCardBody";
import AppCardFooter from "@/components/AppCardFooter";
import AppColumn from "@/components/AppColumn";
import AppDivider from "@/components/AppDivider";
import AppEditMultipleEducationPlanStudentsModal from "@/components/AppEditMultipleEducationPlanStudentsModal";
import AppHeader from "@/components/AppHeader";
import AppInsightDetailModal from "@/components/AppInsightDetailModal.vue";
import AppRow from "@/components/AppRow";
import AppSection from "@/components/AppSection";
import AppStudentLink from "@/components/AppStudentLink";
import AppStudentLinks from "@/components/AppStudentLinks";
import AppText from "@/components/AppText";
import AppTitle from "@/components/AppTitle";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";
import { mapMutations } from "vuex";

export default defineComponent({
    name: "AppEducationPlan",
    components: {
        AppInsightDetailModal,
        AppText,
        AppEditMultipleEducationPlanStudentsModal,
        AppButton,
        AppCardFooter,
        AppCardBody,
        AppArticle,
        AppCard,
        AppColumn,
        AppDivider,
        AppHeader,
        AppRow,
        AppSection,
        AppStudentLink,
        AppStudentLinks,
        AppTitle
    },
    props: {
        bulkId: {
            type: String,
            default: ""
        },
        educationPlanId: {
            type: String,
            default: ""
        },
        groupId: {
            type: String,
            default: ""
        },
        goal: {
            type: String,
            default: ""
        },
        method: {
            type: String,
            default: ""
        },
        students: {
            type: Array,
            default: () => []
        },
        isExpired: Boolean
    },
    setup() {
        const { antlerClass } = antlerComponent("education-plan");

        return {
            antlerClass
        };
    },
    data() {
        return {
            showMultiInsightModal: false,
            showEducationPlanFinishModal: false,
            showDetailModal: false
        };
    },
    methods: {
        ...mapMutations("insightmodal", ["openModal"]),
        handleInsightModalClick() {
            if (this.students.length > 1) {
                this.showMultiInsightModal = true;
            } else {
                this.showDetailModal = true;
            }
        },
        copyInsight() {
            this.openModal({ insightCopyId: this.educationPlanId });
        }
    }
});
</script>
