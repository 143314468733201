<template>
    <TheLayout class="layout--with-sidebar">
        <div class="layout-side layout-side--large">
            <AppLayoutInner>
                <AppTabs :parameter="$gettext('groep')">
                    <AppTab
                        :name="$gettext('Overzicht')"
                        icon="note"
                        :selected="true"
                    >
                        <AppHeader size="smedium">
                            <AppTitle v-translate size="small">
                                Overzicht
                            </AppTitle>
                        </AppHeader>
                        <OverviewGeneral :group-id="groupId" />
                    </AppTab>

                    <AppTab :name="$gettext('Overdracht')" icon="exchange">
                        <AppHeader size="smedium">
                            <AppTitle v-translate size="small">
                                Overdracht
                            </AppTitle>
                        </AppHeader>
                        <OverviewTransfers :group-id="groupId" />
                    </AppTab>
                    <AppTab
                        :name="$gettext('Groepsdocumenten')"
                        icon="document"
                    >
                        <AppHeader size="smedium">
                            <AppTitle v-translate size="small">
                                Groepsdocumenten
                            </AppTitle>
                        </AppHeader>
                        <OverviewDocuments :group-id="groupId" />
                    </AppTab>
                </AppTabs>
            </AppLayoutInner>
        </div>
        <AppLayoutMain>
            <AppLayoutInner variant="background" size="large">
                <AppTabs
                    :parameter="$gettext('inzichten')"
                    tabs-class="u-margin-bottom-small"
                >
                    <AppTab
                        :name="$gettext('Belangrijk')"
                        icon="flag"
                        :selected="true"
                    >
                        <AppLoader
                            v-if="
                                loading > 0 &&
                                    !allInsightsGroupedByBulkId.length
                            "
                        />
                        <template
                            v-else-if="allInsightsGroupedByBulkId.length > 0"
                        >
                            <AppWrapper variant="normal">
                                <AppInsight
                                    v-for="insight in allInsightsGroupedByBulkId"
                                    :key="`${insight.id}-important`"
                                    context="group"
                                    :group-id="groupId"
                                    :insight="insight"
                                />
                            </AppWrapper>
                        </template>
                        <AppOverviewPlaceholder
                            v-else-if="!loading"
                            variant="small centered"
                        >
                            <AppTitle
                                v-translate
                                class="overview-placeholder__title"
                            >
                                Geen belangrijke inzichten
                            </AppTitle>
                            <p v-translate>
                                Inzichten die zijn gemarkeerd als belangrijk bij
                                een leerling binnen deze groep worden hier
                                getoond.
                            </p>
                        </AppOverviewPlaceholder>
                    </AppTab>
                    <AppTab :name="$gettext('Diagnoses')" icon="diagnosis">
                        <AppLoader v-if="loading > 0 && !allDiagnosis.length" />
                        <template v-else-if="allDiagnosis.length > 0">
                            <AppWrapper variant="normal">
                                <AppInsight
                                    v-for="insight in allDiagnosis"
                                    :key="`${insight.id}-diagnosis`"
                                    context="student"
                                    :insight="insight"
                                />
                            </AppWrapper>
                        </template>
                        <AppOverviewPlaceholder
                            v-else-if="!loading"
                            variant="small centered"
                        >
                            <AppTitle
                                v-translate
                                class="overview-placeholder__title"
                            >
                                Geen diagnoses
                            </AppTitle>
                            <p v-translate>
                                Diagnoses die zijn vastgesteld voor leerling
                                binnen deze groep worden hier getoond.
                            </p>
                        </AppOverviewPlaceholder>
                    </AppTab>
                </AppTabs>
            </AppLayoutInner>
        </AppLayoutMain>
    </TheLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppHeader from "@/components/AppHeader";
import AppInsight from "@/components/AppInsight";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppLayoutMain from "@/components/AppLayoutMain.vue";
import AppLoader from "@/components/AppLoader.vue";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppTab from "@/components/AppTab";
import AppTabs from "@/components/AppTabs";
import AppTitle from "@/components/AppTitle.vue";
import AppWrapper from "@/components/AppWrapper.vue";
import GroupedInsights from "@/mixins/GroupedInsights";
import OverviewDocuments from "@/views/groups/OverviewDocuments";
import OverviewGeneral from "@/views/groups/OverviewGeneral";
import OverviewTransfers from "@/views/groups/OverviewTransfers";
import TheLayout from "@/components/TheLayoutDefault";
import { cloneDeep } from "lodash";
import { consoleError } from "@/console";
import gql from "graphql-tag";

const insightFields = `
    id
    type
    typeDisplay
    title
    insight
    insightId
    goal
    method
    date
    important
    attachment {
        filename
        url
    }
    encouraging
    dateSpecific
    dateWeekdays
    educationPlanSubject {
        id
        name
        parent {
            id
            name
        }
    }
    educationalLevelFrom
    educationalLevelTo
    student {
        id
        fullName
        profilePicture {
            filename
            url(
                options: {
                    geometry: "100x100"
                    asynchronous: true
                    crop: "center"
                }
            )
        }
    }
    subjects {
        edges {
            node {
                id
                name
            }
        }
    }
    bulkId
    created
    modified
    expired
`;

export default {
    name: "GroupOverview",
    components: {
        AppLoader,
        AppWrapper,
        AppLayoutMain,
        AppTitle,
        AppLayoutInner,
        OverviewDocuments,
        OverviewGeneral,
        OverviewTransfers,
        AppHeader,
        AppTab,
        AppTabs,
        AppOverviewPlaceholder,
        AppInsight,
        TheLayout
    },
    mixins: [GroupedInsights],
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allDiagnosis: [],
            group: {},
            loading: 0
        };
    },
    computed: {
        ...mapGetters("insightmodal", [
            "createdInsights",
            "updatedInsights",
            "expiredInsights",
            "deletedInsights"
        ]),
        ...mapGetters("online", ["onLine"])
    },
    apollo: {
        allDiagnosis: {
            query: gql`
                query allDiagnosisGroupOverview($id: ID!) {
                    allInsights(
                        groupId: $id
                        notExpired: true
                        noCopies: true
                        insightType: ["diagnosis"]
                    ) {
                        edges {
                            node {
                                ... on Insight {
                                    ${insightFields}
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.groupId
                };
            },
            update(data) {
                if (data.allInsights) {
                    return data.allInsights.edges.map(x => {
                        const node = cloneDeep(x.node);
                        node.type = node.type.toLowerCase();
                        node.subjects = node.subjects.edges.map(x => x.node);
                        return node;
                    });
                } else {
                    return this.allDiagnosis;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van diagnoses."
                    ),
                    type: "error"
                });
            }
        },
        allInsights: {
            query: gql`
                query allImportantInsightsGroupOverview($id: ID!) {
                    allInsights(
                        groupId: $id
                        important: true
                        notExpired: true
                        noCopies: true
                    ) {
                        edges {
                            node {
                                ... on Insight {
                                    ${insightFields}
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.groupId
                };
            },
            update(data) {
                if (data.allInsights) {
                    return data.allInsights.edges.map(x => {
                        const node = cloneDeep(x.node);
                        node.type = node.type.toLowerCase();
                        node.subjects = node.subjects.edges.map(x => x.node);
                        return node;
                    });
                } else {
                    return this.allInsights;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van belangrijke inzichten."
                    ),
                    type: "error"
                });
            }
        }
    },
    watch: {
        createdInsights() {
            this.refetchInsights();
        },
        updatedInsights() {
            this.refetchInsights();
        },
        expiredInsights() {
            this.refetchInsights();
        },
        deletedInsights() {
            this.refetchInsights();
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        refetchInsights() {
            this.$apollo.queries.allDiagnosis.refetch();
            this.$apollo.queries.allInsights.refetch();
            window.scrollTo(0, 0);
        }
    }
};
</script>
