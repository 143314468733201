<template>
    <TheLayout>
        <div class="class-schedules">
            <table
                v-if="groupedTableScheduleRows.length"
                class="table class-schedules-table"
                :style="`width: ${Math.max(scheduleGrades.length * 50, 100)}vw`"
            >
                <thead>
                    <tr class="class-schedule-row">
                        <template v-for="grade in scheduleGrades">
                            <th
                                v-if="showSidebar"
                                :key="`table-head-sidebar-${grade}`"
                                class="class-schedule-row-side"
                            />
                            <th
                                :key="`table-head-title-${grade}`"
                                class="class-schedule__table-header"
                            >
                                <translate
                                    tag="h2"
                                    class="title"
                                    :translate-params="{ grade }"
                                >
                                    Groep %{grade}
                                </translate>
                            </th>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <!-- prettier-ignore -->
                    <tr
                        v-for="(scheduleRows, index) in groupedTableScheduleRows"
                        :key="`table-row-${index}`"
                        class="class-schedule-row"
                    >
                        <template v-for="(scheduleRow, column) in scheduleRows">
                            <td
                                v-if="showSidebar"
                                :key="`table-column-sidebar-${column}`"
                                class="class-schedule-row-side"
                            >
                                <template v-if="scheduleRow">
                                    <time class="class-schedule-row__time">{{
                                        scheduleRow.startTime
                                    }}</time>
                                    <time
                                        v-if="scheduleRow.isLastRow"
                                        class="class-schedule-row__time"
                                    >
                                        {{ scheduleRow.endTime }}
                                    </time>
                                </template>
                            </td>
                            <td
                                :key="`table-column-content-${column}`"
                                :class="[
                                    'class-schedule-row-content',
                                    { 'class-schedule-row-content--no-border' : !scheduleRow }
                                ]"
                            >
                                <div v-if="scheduleRow">
                                    <div class="class-schedule-row-header">
                                        <div v-if="scheduleRow.subjectBundle" class="title class-schedule__subject">
                                            {{ scheduleRow.subjectBundle.name }}
                                        </div>

                                        <div
                                            :class="scheduleRow.subjectBundle ? 'class-schedule__theme' : 'title class-schedule__subject'"
                                        >
                                            {{ scheduleRow.subjectDisplay }}
                                        </div>

                                        <div
                                            v-if="scheduleRow.theme"
                                            class="class-schedule__theme"
                                        >
                                            {{ scheduleRow.theme }}
                                        </div>
                                    </div>
                                    <AppPurpose
                                        v-if="scheduleRow.purpose"
                                        class="class-schedule-row-purpose"
                                    >
                                        <AppIcon
                                            name="target"
                                            variant="tiny"
                                            class="u-margin-right-small"
                                        />
                                        <span>{{ scheduleRow.purpose }}</span>
                                    </AppPurpose>
                                    <div
                                        v-if="scheduleRow.notes"
                                        class="class-schedule-row-note article"
                                    >
                                        <!-- eslint-disable-next-line -->
                                        <p class="t-pre-wrap">{{ scheduleRow.notes }}</p>
                                    </div>
                                </div>
                            </td>
                        </template>
                    </tr>
                    <tr style="height: 100%">
                        <template v-for="grade in scheduleGrades">
                            <td
                                v-if="showSidebar"
                                :key="`table-column-sidebar-${grade}-filler`"
                                class="class-schedule-row-side"
                            />
                            <td :key="`table-column-content-${grade}-filler`" />
                        </template>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <template v-for="grade in scheduleGrades">
                            <td
                                v-if="showSidebar"
                                :key="`table-footer-sidebar-${grade}`"
                            >
                                <div class="class-schedule-options">
                                    <div
                                        v-if="showSidebar"
                                        class="class-schedule-row-side class-schedule-options-inner"
                                    >
                                        <AppButton
                                            :key="showSidebar"
                                            v-translate
                                            variant="link"
                                            icon="clock"
                                            @click="showSidebar = false"
                                        >
                                            Verberg
                                        </AppButton>
                                    </div>
                                </div>
                            </td>
                            <td :key="`table-footer-spacer-${grade}`">
                                <div class="class-schedule-options">
                                    <div
                                        v-if="!showSidebar"
                                        class="class-schedule-row-content class-schedule-options-inner"
                                    >
                                        <AppButton
                                            :key="showSidebar"
                                            v-translate
                                            variant="link"
                                            icon="clock"
                                            @click="showSidebar = true"
                                        >
                                            Toon
                                        </AppButton>
                                    </div>
                                </div>
                            </td>
                        </template>
                    </tr>
                </tfoot>
            </table>
        </div>
    </TheLayout>
</template>

<script>
import { cloneDeep, isEqual } from "lodash";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import AppPurpose from "@/components/AppPurpose.vue";
import TheLayout from "@/components/TheLayoutDefault";

import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    name: "GroupScheduleClassView",
    components: { AppPurpose, AppButton, AppIcon, TheLayout },
    props: {
        date: {
            type: Date,
            required: true
        },
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            groupSchedule: {},
            loading: 0,
            showSidebar: true
        };
    },
    computed: {
        dateISO() {
            return this.$moment(this.date).format("YYYY-MM-DD");
        },
        scheduleGrades() {
            if (!this.groupSchedule.id) return [];
            return this.groupSchedule.schedules.reduce((acc, schedule) => {
                acc.push(schedule.grade && schedule.grade.grade);
                return acc;
            }, []);
        },
        groupedTableScheduleRows() {
            if (!this.groupSchedule.id) return [];

            const scheduleRows = this.groupSchedule.schedules
                .reduce((acc, schedule, index) => {
                    return [
                        ...acc,
                        ...schedule.scheduleRows.map((x, i) => ({
                            ...x,
                            index: i,
                            column: index + 1,
                            grade: schedule.grade && schedule.grade.grade
                        }))
                    ];
                }, [])
                .sort((a, b) => {
                    if (a.startTime === b.startTime) {
                        return a.index > b.index ? 1 : -1;
                    }
                    return this.$moment(a.startTime, "HHmm") >
                        this.$moment(b.startTime, "HHmm")
                        ? 1
                        : -1;
                });

            if (!scheduleRows.length) return [];

            function belongTogether(checkEquals, previousTime, currentTime) {
                return checkEquals
                    ? previousTime === currentTime
                    : previousTime !== currentTime;
            }

            const emptyGroup = this.scheduleGrades.reduce(
                (acc, grade, index) => {
                    acc[index + 1] = null;
                    return acc;
                },
                {}
            );

            let groupingEqualTimes =
                scheduleRows.length > 1 &&
                scheduleRows[0].startTime === scheduleRows[1].startTime;
            let currentGroup = cloneDeep(emptyGroup);
            const result = [currentGroup];

            const addToCurrentGroup = value => {
                if (currentGroup[value.column] != null) {
                    currentGroup = cloneDeep(emptyGroup);
                    result.push(currentGroup);
                }
                currentGroup[value.column] = value;
            };

            const lastValue = scheduleRows.reduce(
                (previousValue, currentValue) => {
                    if (
                        belongTogether(
                            groupingEqualTimes,
                            previousValue.startTime,
                            currentValue.startTime
                        )
                    ) {
                        addToCurrentGroup(previousValue);
                        return currentValue;
                    }

                    if (groupingEqualTimes) {
                        addToCurrentGroup(previousValue);
                        currentGroup = cloneDeep(emptyGroup);
                        result.push(currentGroup);
                    } else {
                        if (!isEqual(emptyGroup, currentGroup)) {
                            currentGroup = cloneDeep(emptyGroup);
                            result.push(currentGroup);
                        }
                        addToCurrentGroup(previousValue);
                    }

                    groupingEqualTimes = !groupingEqualTimes;
                    return currentValue;
                }
            );
            addToCurrentGroup(lastValue);
            return result;
        }
    },
    apollo: {
        groupSchedule: {
            query: gql`
                query allGroupSchedulesGroupScheduleClassView(
                    $date: Date!
                    $groupId: ID!
                ) {
                    allGroupSchedules(
                        date: $date
                        groupId: $groupId
                        first: 1
                    ) {
                        edges {
                            node {
                                id
                                schedules {
                                    edges {
                                        node {
                                            id
                                            grade {
                                                id
                                                grade
                                            }
                                            scheduleRows {
                                                edges {
                                                    node {
                                                        id
                                                        startTime
                                                        endTime
                                                        notes
                                                        purpose
                                                        subject {
                                                            id
                                                            name
                                                            parent {
                                                                id
                                                                name
                                                            }
                                                        }
                                                        subjectBundle {
                                                            id
                                                            name
                                                        }
                                                        theme
                                                        subjectDescription
                                                        isBreak
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    date: this.dateISO,
                    groupId: this.groupId
                };
            },
            update(data) {
                if (data.allGroupSchedules) {
                    if (data.allGroupSchedules.edges.length === 0) return [];

                    const groupSchedule = {
                        ...data.allGroupSchedules.edges[0].node
                    };
                    groupSchedule.schedules = groupSchedule.schedules.edges
                        .filter(x => x.node.scheduleRows.edges.length > 0)
                        .map(schedule => ({
                            ...schedule.node,
                            scheduleRows: schedule.node.scheduleRows.edges.map(
                                (row, index) => ({
                                    ...row.node,
                                    startTime: this.formatTime(
                                        row.node.startTime
                                    ),
                                    endTime: this.formatTime(row.node.endTime),
                                    isLastRow:
                                        index + 1 ===
                                        schedule.node.scheduleRows.edges.length,
                                    subjectDisplay: row.node.isBreak
                                        ? this.$gettext("Pauze")
                                        : row.node.subject
                                        ? row.node.subject.parent
                                            ? row.node.subject.parent.name
                                            : row.node.subject.name
                                        : row.node.subjectDescription
                                })
                            )
                        }));
                    return groupSchedule;
                } else {
                    return this.groupSchedule;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de planning."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        formatTime(val) {
            return this.$moment(val, "HHmm").format("HH:mm");
        }
    }
};
</script>

<style lang="sass">
.class-schedules
    display: flex
    flex: 1

    color: #000

    overflow: scroll

.class-schedules-table
    overflow: auto
    min-height: 100vh

    table-layout: fixed

.class-schedule__table-header
    padding:
        top: $spacing-large
        right: $spacing-regular
        bottom: $spacing-regular
        left: $spacing-regular
    min-width: calc(50vw - 120px) // minus side

    text-align: left

.class-schedule-header
    flex: 1
    padding: $spacing-regular

.class-schedule-row-side
    padding: $spacing-small $spacing-regular
    width: 120px
    min-width: 1px

    background-color: $color-blue-lighter

.class-schedule-row__time
    @include font-size(20px)
    font-weight: $strong-weight

.class-schedule-row-header
    display: flex
    align-items: center

.class-schedule-row-content:not(.class-schedule-row-content--no-border)
    flex: 1
    padding: $spacing-small $spacing-regular
    min-width: calc(50vw - 120px) // minus side
    white-space: nowrap

    border-bottom:
        width: 1px
        style: solid
        color: #000

.class-schedule__subject
    @include font-size(20px)
    font-weight: $strong-weight

.class-schedule__theme
    margin-left: $spacing-small
    padding-left: $spacing-small

    font-weight: $strong-weight

    border-left:
        width: 1px
        color: #000
        style: solid

.class-schedule-row-purpose
    flex: 1
    justify-content: flex-start
    margin-top: $spacing-small

    @include font-size(14px)
    color: #fff
    text-align: left

    background-color: #000
    border-radius: 20px

.class-schedule-row-note
    margin-top: $spacing-small

// Options

.class-schedule-options
    display: flex
    flex: 1
    align-items: flex-end

    .class-schedule-row-side
        justify-content: flex-end
        align-items: flex-start
        padding:
            right: $spacing-regular
            left: $spacing-regular

    .class-schedule-row-content
        border-bottom: 0

.class-schedule-options-inner
    display: flex
    align-items: flex-end
    height: 100%

    line-height: 1
</style>
