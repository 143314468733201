<template>
    <TheLayout class="layout--vertical">
        <AppLayoutInner variant="centered">
            <AppOverviewPlaceholder variant="small">
                <AppHeader>
                    <AppTitle
                        v-translate
                        size="large"
                        class="overview-placeholder__title"
                    >
                        Geen ontwikkelplannen
                    </AppTitle>
                </AppHeader>

                <p v-translate>
                    De leerlingen in deze groep hebben nog geen
                    ontwikkelplannen.
                </p>
            </AppOverviewPlaceholder>
        </AppLayoutInner>
    </TheLayout>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppLayoutInner from "@/components/AppLayoutInner";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppTitle from "@/components/AppTitle";
import TheLayout from "@/components/TheLayoutDefault";

export default {
    name: "EducationPlanEmpty",
    components: {
        AppTitle,
        AppHeader,
        AppLayoutInner,
        AppOverviewPlaceholder,
        TheLayout
    }
};
</script>
