<template>
    <AppPage>
        <AppHeader
            v-if="reportCollectionId && reportCollection.id"
            class="d-flex"
        >
            <AppButtonGroup class="u-margin-left-auto">
                <div
                    v-if="reportCollection.taskStatus === 'FAILED'"
                    key="failed"
                >
                    <translate>
                        Het maken van PDF-rapporten is mislukt
                    </translate>
                    <AppButton
                        icon="refresh"
                        variant="medium link link-underline"
                        :disabled="!onLine"
                        :loading="loading > 0"
                        @click="retryCollectionTask"
                    >
                        <translate>Probeer opnieuw</translate>
                    </AppButton>
                </div>
                <a
                    v-else-if="reportCollection.taskStatus === 'SUCCESS'"
                    key="success"
                    class="link"
                    download
                    :href="reportCollection.collection.url"
                    target="_blank"
                >
                    <AppIcon
                        class="link__icon u-margin-right-small"
                        name="download"
                    />
                    <span class="t-decoration-underline">
                        <translate>Download rapporten</translate>
                    </span>
                </a>
                <div v-else key="pending" class="d-flex--with-center">
                    <AppLoader class="u-margin-right-small" />
                    <translate>
                        PDF-rapporten maken, dit duurt enkele minuten
                    </translate>
                </div>
            </AppButtonGroup>
        </AppHeader>

        <AppOverviewList class="u-margin-bottom">
            <tbody class="overview-list-body overview-list-body--bordered">
                <tr
                    v-for="report in allReports"
                    :key="report.id"
                    class="overview-list__row overview-list__row--clickable"
                    @click="reportId = report.id"
                >
                    <td
                        class="overview-list__column overview-list__column--avatar"
                    >
                        <AppAvatar
                            :image="
                                report.student.profilePicture.url || undefined
                            "
                            size="small"
                        />
                    </td>
                    <td class="overview-list__column">
                        {{ report.student.fullName }}
                    </td>
                    <td class="overview-list__column">
                        <translate
                            :translate-params="{
                                grade: report.grade.grade
                            }"
                        >
                            Groep %{grade}
                        </translate>
                    </td>
                    <td class="overview-list__column">
                        {{ report.statusDisplay }}
                    </td>
                </tr>
            </tbody>
        </AppOverviewList>

        <AppReportDetailModal
            v-model="reportId"
            @next="reportId = nextReport ? nextReport.id : undefined"
        />

        <AppContent variant="rounded background">
            <AppHeader size="small">
                <AppTitle as="h2" class="subtitle">
                    <translate>Rapporten status</translate>
                </AppTitle>
            </AppHeader>

            <template
                v-if="
                    hasDrafts ||
                        (daysUntilStatusIrreversible != null &&
                            daysUntilStatusIrreversible >= 0)
                "
            >
                <div class="t-size-small">
                    <div v-translate>
                        <strong>Concept rapporten</strong> gebruiken de laatste
                        onderwijsniveaus, u kunt opmerkingen toevoegen en de
                        CITO scores invullen.
                    </div>
                    <div v-translate class="u-margin-bottom">
                        <strong>Definitieve rapporten</strong> kunt u
                        downloaden, de onderwijsniveaus staan vast en u kunt
                        geen opmerkingen en / of CITO scores meer toevoegen.
                    </div>

                    <template v-if="!hasDrafts">
                        <div
                            v-if="daysUntilStatusIrreversible === 0"
                            key="zero-days"
                            v-translate
                        >
                            <strong>Terug naar concept rapport</strong> dit is
                            de laatste dag dat de rapporten nog op concept gezet
                            kunnen worden om opmerkingen en/of CITO scores te
                            wijzigen.
                        </div>
                        <div
                            v-else
                            key="days"
                            v-translate="{
                                daysLeft: daysUntilStatusIrreversible
                            }"
                            :translate-n="daysUntilStatusIrreversible"
                            translate-plural="<strong>Terug naar concept rapport</strong> u heeft
                            nog <strong>%{daysLeft} dagen</strong> de mogelijkheid om
                            de rapporten weer op concept te zetten om
                            opmerkingen en/of CITO scores te wijzigen."
                        >
                            <strong>Terug naar concept rapport</strong> u hebt
                            nog <strong>%{daysLeft} dag</strong> de mogelijkheid
                            om de rapporten weer op concept te zetten om
                            opmerkingen en/of CITO scores te wijzigen.
                        </div>
                        <div v-translate>
                            <strong>Let op:</strong> dit zorgt ervoor dat de
                            laatste onderwijsniveaus worden gebruikt in de
                            rapporten. Dit kan kan niet ongedaan worden gemaakt.
                        </div>
                    </template>
                </div>

                <div class="report-detail__footer">
                    <AppButton
                        v-if="hasDrafts"
                        variant="link link-large link-underline"
                        :disabled="!onLine || loading > 0"
                        icon="trash"
                        icon-class="icon--small"
                        @click="deleteReports"
                    >
                        <translate :translate-params="{ number }">
                            Verwijder alle concept "Rapport %{number}" rapporten
                        </translate>
                    </AppButton>

                    <div class="report-detail__footer-action">
                        <AppButton
                            v-if="hasDrafts"
                            key="final"
                            variant="secondary huge"
                            :disabled="!onLine"
                            :loading="loading > 0"
                            type="submit"
                            @click="updateStatusReports('final')"
                        >
                            <translate>Maak rapporten definitief</translate>
                        </AppButton>
                        <AppButton
                            v-else
                            variant="secondary huge"
                            :disabled="!onLine"
                            :loading="loading > 0"
                            type="submit"
                            @click="updateStatusReports('draft')"
                        >
                            <translate>Zet rapporten op concept</translate>
                        </AppButton>
                    </div>
                </div>
            </template>
            <div v-else class="t-size-small">
                <translate tag="div">
                    De rapporten zijn definitief en kunnen niet meer gewijzigd
                    worden.
                </translate>
            </div>
        </AppContent>
    </AppPage>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppAvatar from "@/components/AppAvatar";
import AppButton from "@/components/AppButton";
import AppButtonGroup from "@/components/AppButtonGroup.vue";
import AppContent from "@/components/AppContent";
import AppHeader from "@/components/AppHeader";
import AppIcon from "@/components/AppIcon";
import AppLoader from "@/components/AppLoader";
import AppOverviewList from "@/components/AppOverviewList.vue";
import AppPage from "@/components/AppPage.vue";
import AppReportDetailModal from "@/components/AppReportDetailModal";
import AppTitle from "@/components/AppTitle.vue";
import FormSave from "@/mixins/FormSave";

import { consoleError } from "@/console";
import gql from "graphql-tag";

export default {
    name: "ReportDetail",
    components: {
        AppTitle,
        AppOverviewList,
        AppPage,
        AppButtonGroup,
        AppHeader,
        AppContent,
        AppLoader,
        AppIcon,
        AppReportDetailModal,
        AppButton,
        AppAvatar
    },
    mixins: [FormSave],
    props: {
        groupId: {
            type: String,
            required: true
        },
        number: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            allReports: [],
            reportCollection: {},
            reportId: undefined
        };
    },
    computed: {
        ...mapGetters("online", ["onLine"]),
        hasDrafts() {
            return this.allReports.some(node => node.status === "DRAFT");
        },
        daysUntilStatusIrreversible() {
            return this.allReports.reduce((acc, node) => {
                if (
                    node.daysUntilStatusIrreversible != null &&
                    (acc == null || node.daysUntilStatusIrreversible < acc)
                ) {
                    return node.daysUntilStatusIrreversible;
                }
                return acc;
            }, null);
        },
        nextReport() {
            if (!this.allReports.length) return;
            const nextIndex =
                this.allReports.findIndex(node => node.id === this.reportId) +
                1;
            return this.allReports[
                nextIndex < this.allReports.length ? nextIndex : 0
            ];
        },
        reportCollectionId() {
            const reportCollections = this.allReports.length
                ? this.allReports[0].reportCollections
                : [];
            return reportCollections.length ? reportCollections[0].id : null;
        }
    },
    apollo: {
        allReports: {
            query: gql`
                query allReportsReportDetail($groupId: ID!, $number: Int!) {
                    allReports(groupId: $groupId, number: $number) {
                        edges {
                            node {
                                id
                                student {
                                    id
                                    fullName
                                    profilePicture {
                                        url(
                                            options: {
                                                geometry: "400x400"
                                                asynchronous: true
                                                crop: "center"
                                            }
                                        )
                                    }
                                }
                                grade {
                                    id
                                    grade
                                }
                                status
                                statusDisplay
                                number
                                daysUntilStatusIrreversible
                                reportCollections {
                                    edges {
                                        node {
                                            id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    groupId: this.groupId,
                    number: this.number
                };
            },
            update(data) {
                if (data.allReports) {
                    return data.allReports.edges.map(x => {
                        const node = { ...x.node };
                        node.reportCollections = node.reportCollections.edges.map(
                            x => x.node
                        );
                        return node;
                    });
                } else {
                    return this.allReports;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van rapporten."
                    ),
                    type: "error"
                });
            }
        },
        reportCollection: {
            query: gql`
                query reportCollectionReportDetail($id: ID!) {
                    reportCollection(id: $id) {
                        id
                        taskStatus
                        collection {
                            url
                        }
                    }
                }
            `,
            variables() {
                return {
                    id: this.reportCollectionId
                };
            },
            result({ data, loading }) {
                if (!loading && data.reportCollection) {
                    if (data.reportCollection.taskStatus === "PENDING") {
                        this.$apollo.queries.reportCollection.startPolling(
                            5000
                        );
                    } else {
                        this.$apollo.queries.reportCollection.stopPolling();
                    }
                }
            },
            skip() {
                return !this.reportCollectionId;
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        _processMutation(mutation, successCallback) {
            this.loading++;
            this.$apollo
                .mutate(mutation)
                .then(data => {
                    let errors = [];
                    for (const k in data.data) {
                        if (
                            Object.prototype.hasOwnProperty.call(data.data, k)
                        ) {
                            errors = errors.concat(data.data[k].errors || []);
                        }
                    }

                    if (errors.length === 0) {
                        successCallback && successCallback();
                    } else {
                        this.formError();
                    }
                })
                .catch(err => {
                    this.formError(err);
                })
                .finally(() => {
                    this.loading--;
                });
        },
        updateStatusReports(status) {
            const input = {
                group: atob(this.groupId).split(":")[1],
                number: this.number,
                status
            };
            this._processMutation(
                {
                    mutation: gql`
                        mutation updateStatusReportsReportDetail(
                            $input: UpdateStatusReportsMutationInput!
                        ) {
                            updateStatusReports(input: $input) {
                                reports {
                                    id
                                    status
                                    statusDisplay
                                    daysUntilStatusIrreversible
                                    reportCollections {
                                        edges {
                                            node {
                                                id
                                            }
                                        }
                                    }
                                }
                                errors {
                                    field
                                    messages
                                }
                            }
                        }
                    `,
                    variables: { input }
                },
                () => {
                    this.showMessage({
                        message: this.$gettext(
                            "Status is succesvol gewijzigd."
                        ),
                        type: "success"
                    });
                }
            );
        },
        retryCollectionTask() {
            this._processMutation(
                {
                    mutation: gql`
                        mutation scheduleReportCollectionTaskReportDetail(
                            $id: ID!
                        ) {
                            scheduleReportCollectionTask(input: { id: $id }) {
                                reportCollection {
                                    id
                                    taskStatus
                                    collection {
                                        url
                                    }
                                }
                                errors {
                                    field
                                    messages
                                }
                            }
                        }
                    `,
                    variables: {
                        id: atob(this.reportCollectionId).split(":")[1]
                    }
                },
                () => {
                    this.showMessage({
                        message: this.$gettext(
                            "Het maken van PDF-rapporten wordt opnieuw gestart."
                        ),
                        type: "success"
                    });
                }
            );
        },
        deleteReports() {
            if (
                confirm(
                    this.$gettext(
                        "Weet u zeker dat u alle concept rapporten wil verwijderen?"
                    )
                )
            ) {
                this._processMutation(
                    {
                        mutation: gql`
                            mutation deleteReportsReportDetail($id: ID!) {
                                deleteReports(input: { group: $id }) {
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        `,
                        variables: {
                            id: atob(this.groupId).split(":")[1]
                        },
                        refetchQueries: [
                            {
                                query: gql`
                                    query($groupId: ID!) {
                                        allReports(groupId: $groupId) {
                                            edges {
                                                node {
                                                    id
                                                    number
                                                    status
                                                }
                                            }
                                        }
                                    }
                                `,
                                variables: { groupId: this.groupId }
                            }
                        ],
                        awaitRefetchQueries: true
                    },
                    () => {
                        this.showMessage({
                            message: this.$gettext(
                                "Concept rapporten zijn succesvol verwijderd."
                            ),
                            type: "success"
                        });
                        this.$router.push({ name: "group_report" }, () => {});
                    }
                );
            }
        }
    }
};
</script>

<style lang="sass">
.report-detail__footer
    display: flex
    flex-direction: column
    gap: 16px
    width: 100%

    +mq-desktop
        flex-direction: row

.report-detail__footer-action
    +mq-desktop
        margin-left: auto
</style>
