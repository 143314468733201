<template>
    <AppLayout variant="vertical background">
        <AppLayoutInner variant="centered">
            <AppOverviewPlaceholder class="overview-placeholder--small">
                <AppTitle v-translate class="overview-placeholder__title">
                    Geen onderwijsniveaus
                </AppTitle>
                <p v-translate>
                    De leerlingen in deze groep hebben nog geen
                    onderwijsniveaus.
                </p>
            </AppOverviewPlaceholder>
        </AppLayoutInner>
    </AppLayout>
</template>

<script>
import AppLayout from "@/components/AppLayout.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppTitle from "@/components/AppTitle.vue";

export default {
    name: "LevelEmpty",
    components: { AppTitle, AppLayoutInner, AppLayout, AppOverviewPlaceholder }
};
</script>
