<template>
    <AppLayoutInner :variant="!groupSchedule.id ? 'centered' : ''">
        <template v-if="groupSchedule.id">
            <AppPageHeader variant="centered">
                <div class="d-flex">
                    <AppTitle as="h2" class="subtitle">
                        <AppText class="text--date">
                            {{ dateName }}
                        </AppText>
                    </AppTitle>
                    <AppLink
                        variant="small"
                        class="page-header__button"
                        :to="{
                            name: 'group_schedule_update',
                            params: { groupScheduleId: groupSchedule.id }
                        }"
                    >
                        <AppIcon
                            name="edit"
                            class="icon--small u-margin-right-small"
                        />
                        <translate>Bewerken</translate>
                    </AppLink>
                </div>

                <div class="u-margin-left-auto">
                    <AppButton
                        icon="out"
                        variant="link link-small"
                        class="page-header__button"
                        @click.prevent="openClassView"
                    >
                        <translate>Klasweergave</translate>
                    </AppButton>
                </div>
            </AppPageHeader>
            <AppScheduleGroupWrapper v-if="groupedScheduleRows.length">
                <AppScheduleGroup
                    v-for="(scheduleGroup, index) in groupedScheduleRows"
                    :key="`schedule-group-${index}`"
                    :columns-missing-schedule-rows="columnsMissingScheduleRows"
                    :group-id="groupId"
                    :group-schedule="groupSchedule"
                    :index="index"
                    :schedule-grades="scheduleGrades"
                    :schedule-group="scheduleGroup"
                />
            </AppScheduleGroupWrapper>
            <AppMessage
                v-else
                class="u-margin-bottom-large"
                variant="rounded info"
            >
                <translate>De planning is nog niet compleet.</translate>

                <template #options>
                    <AppLink
                        v-if="groupSchedule"
                        variant="white small"
                        :to="{
                            name: 'group_schedule_update',
                            params: { groupScheduleId: groupSchedule.id }
                        }"
                    >
                        <AppIcon
                            name="edit"
                            variant="small"
                            class="u-margin-right-small"
                        />
                        <translate>Planning compleet maken</translate>
                    </AppLink>
                </template>
            </AppMessage>

            <AppContent variant="rounded shadow">
                <AppRow>
                    <AppColumn class="column-full--lte-tablet-portrait">
                        <AppHeader variant="centered">
                            <AppTitle as="h2" class="subtitle header__subtitle">
                                <AppIcon
                                    name="note"
                                    class="u-margin-right-small"
                                />
                                <translate>Notities</translate>
                            </AppTitle>
                        </AppHeader>

                        <AppSingleTextareaForm
                            v-model="formValues.notes"
                            :counter="560"
                            :disabled="loading > 0 || !onLine"
                            field="notes"
                            :form-mutation="notesMutation"
                            :hint="
                                $gettext(
                                    'Ruimte voor een algemene notitie voor deze dag.'
                                )
                            "
                            persistent-hint
                            @formsuccess="
                                showMessage({
                                    message: $gettext(
                                        'Notities zijn succesvol opgeslagen.'
                                    ),
                                    type: 'success'
                                })
                            "
                        />
                    </AppColumn>
                    <AppColumn class="column-full--lte-tablet-portrait">
                        <AppHeader>
                            <h2 v-translate class="subtitle">
                                Inzichten
                            </h2>
                        </AppHeader>

                        <AppInsightsWrapper variant="bordered">
                            <template v-if="allInsightsGroupedByBulkId.length">
                                <AppInsight
                                    v-for="insight in allInsightsGroupedByBulkId"
                                    :key="insight.id"
                                    context="schedule"
                                    :group-id="groupId"
                                    :insight="insight"
                                />
                            </template>
                            <p
                                v-else-if="!loading"
                                v-translate
                                class="t-color-blue-light"
                            >
                                Er zijn geen inzichten voor deze dag.
                            </p>
                        </AppInsightsWrapper>
                    </AppColumn>
                </AppRow>
            </AppContent>
        </template>
        <template v-else-if="!loading">
            <AppOverviewPlaceholder
                key="noSchedule"
                class="overview-placeholder--small"
            >
                <AppTitle
                    v-translate
                    size="large"
                    class="overview-placeholder__title"
                >
                    Geen planning
                </AppTitle>
                <p v-translate="{ date: dateName.toLowerCase() }">
                    Er is geen planning voor %{date} toegevoegd
                </p>
                <template #button>
                    <AppButton
                        data-testid="button-create-schedule"
                        variant="primary medium-with-icon"
                        icon="plus"
                        :disabled="!onLine || isHistoric"
                        :loading="loading > 0"
                        @click="createSchedule"
                    >
                        <translate>Dag toevoegen</translate>
                    </AppButton>
                </template>
            </AppOverviewPlaceholder>
        </template>
    </AppLayoutInner>
</template>

<script>
import { cloneDeep, isEqual } from "lodash";
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppColumn from "@/components/AppColumn";
import AppContent from "@/components/AppContent";
import AppHeader from "@/components/AppHeader";
import AppIcon from "@/components/AppIcon";
import AppInsight from "@/components/AppInsight";
import AppInsightsWrapper from "@/components/AppInsightsWrapper";
import AppLayoutInner from "@/components/AppLayoutInner";
import AppLink from "@/components/AppLink.vue";
import AppMessage from "@/components/AppMessage";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppPageHeader from "@/components/AppPageHeader.vue";
import AppRow from "@/components/AppRow";
import AppScheduleGroup from "@/components/AppScheduleGroup";
import AppScheduleGroupWrapper from "@/components/AppScheduleGroupWrapper";
import AppSingleTextareaForm from "@/components/AppSingleTextareaForm";
import AppText from "@/components/AppText.vue";

import AppTitle from "@/components/AppTitle.vue";
import GroupedInsights from "@/mixins/GroupedInsights";
import { consoleError } from "@/console";
import { getScheduleRows } from "@/utils/getScheduleRows";
import gql from "graphql-tag";

export default {
    name: "GroupScheduleDetail",
    components: {
        AppLink,
        AppPageHeader,
        AppTitle,
        AppText,
        AppLayoutInner,
        AppColumn,
        AppRow,
        AppContent,
        AppInsightsWrapper,
        AppButton,
        AppHeader,
        AppIcon,
        AppInsight,
        AppMessage,
        AppOverviewPlaceholder,
        AppScheduleGroup,
        AppScheduleGroupWrapper,
        AppSingleTextareaForm
    },
    mixins: [GroupedInsights],
    props: {
        date: {
            type: Date,
            required: true
        },
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allInsights: [],
            formValues: {
                notes: ""
            },
            loading: 0,
            groupSchedule: {},
            showModal: false
        };
    },
    computed: {
        ...mapGetters("insightmodal", [
            "createdInsights",
            "expiredInsights",
            "deletedInsights",
            "updatedInsights"
        ]),
        ...mapGetters("online", ["onLine"]),
        isHistoric() {
            const selectedDate = this.$moment(this.date);
            return selectedDate.isBefore(this.$moment().startOf("day"));
        },
        dateISO() {
            return this.$moment(this.date).format("YYYY-MM-DD");
        },
        dateName() {
            const date = this.$moment(this.date);
            if (date.isSame(this.$moment(), "day")) {
                return this.$gettext("Vandaag");
            } else if (date.isSame(this.$moment().subtract(1, "day"), "day")) {
                return this.$gettext("Gisteren");
            } else if (date.isSame(this.$moment().add(1, "day"), "day")) {
                return this.$gettext("Morgen");
            } else {
                return date.format("dddd LL");
            }
        },
        numericId() {
            return this.groupSchedule.id
                ? atob(this.groupSchedule.id).split(":")[1]
                : "";
        },
        columnsMissingScheduleRows() {
            if (!this.groupSchedule.id) return [];
            return this.groupSchedule.schedules.reduce(
                (acc, schedule, index) => {
                    if (!schedule.scheduleRows.length) acc.push(index + 1);
                    return acc;
                },
                []
            );
        },
        scheduleGrades() {
            if (!this.groupSchedule.id) return [];
            return this.groupSchedule.schedules.reduce((acc, schedule) => {
                acc.push(schedule.grade && schedule.grade.grade);
                return acc;
            }, []);
        },
        groupedScheduleRows() {
            if (!this.groupSchedule.id) return [];

            const scheduleRows = this.groupSchedule.schedules
                .filter(schedule => schedule.status.toLowerCase() === "final")
                .reduce((acc, schedule, index) => {
                    return [
                        ...acc,
                        ...schedule.scheduleRows.map((x, i) => ({
                            ...x,
                            index: i,
                            column: index + 1,
                            grade: schedule.grade && schedule.grade.grade
                        }))
                    ];
                }, [])
                .sort((a, b) => {
                    if (a.startTime === b.startTime) {
                        return a.index > b.index ? 1 : -1;
                    }
                    return this.$moment(a.startTime, "HHmm") >
                        this.$moment(b.startTime, "HHmm")
                        ? 1
                        : -1;
                });

            if (!scheduleRows.length) return [];

            function belongTogether(checkEquals, previousTime, currentTime) {
                return checkEquals
                    ? previousTime === currentTime
                    : previousTime !== currentTime;
            }

            const emptyGroup = this.scheduleGrades.reduce(
                (acc, grade, index) => {
                    acc[index + 1] = [];
                    return acc;
                },
                {}
            );

            let groupingEqualTimes =
                scheduleRows.length > 1 &&
                scheduleRows[0].startTime === scheduleRows[1].startTime;
            let currentGroup = cloneDeep(emptyGroup);
            const result = [currentGroup];

            const lastValue = scheduleRows.reduce(
                (previousValue, currentValue) => {
                    if (
                        belongTogether(
                            groupingEqualTimes,
                            previousValue.startTime,
                            currentValue.startTime
                        )
                    ) {
                        currentGroup[previousValue.column].push(previousValue);
                        return currentValue;
                    }

                    if (groupingEqualTimes) {
                        currentGroup[previousValue.column].push(previousValue);
                        currentGroup = cloneDeep(emptyGroup);
                        result.push(currentGroup);
                    } else {
                        if (!isEqual(emptyGroup, currentGroup)) {
                            currentGroup = cloneDeep(emptyGroup);
                            result.push(currentGroup);
                        }
                        currentGroup[previousValue.column].push(previousValue);
                    }

                    groupingEqualTimes = !groupingEqualTimes;
                    return currentValue;
                }
            );
            currentGroup[lastValue.column].push(lastValue);
            return result;
        }
    },
    apollo: {
        allInsights: {
            query: gql`
                query allInsightsScheduleDetail($groupId: ID!, $date: Date!) {
                    allInsights(
                        groupId: $groupId
                        date: $date
                        notExpired: true
                    ) {
                        edges {
                            node {
                                ... on Insight {
                                    id
                                    insightId
                                    type
                                    typeDisplay
                                    title
                                    insight
                                    date
                                    important
                                    attachment {
                                        filename
                                        url
                                    }
                                    encouraging
                                    dateSpecific
                                    dateWeekdays
                                    educationalLevelFrom
                                    educationalLevelTo
                                    educationalLevelSubject {
                                        id
                                        name
                                    }
                                    educationalLevelGrade {
                                        grade
                                    }
                                    student {
                                        id
                                        fullName
                                        profilePicture {
                                            filename
                                            url(
                                                options: {
                                                    geometry: "100x100"
                                                    asynchronous: true
                                                    crop: "center"
                                                }
                                            )
                                        }
                                    }
                                    subjects {
                                        edges {
                                            node {
                                                id
                                                name
                                            }
                                        }
                                    }
                                    educationPlanSubject {
                                        id
                                        name
                                        parent {
                                            id
                                            name
                                        }
                                    }
                                    created
                                    modified
                                    expired
                                    bulkId
                                    goal
                                    method
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    groupId: this.groupId,
                    date: this.dateISO
                };
            },
            update(data) {
                if (data.allInsights) {
                    return data.allInsights.edges.map(x => {
                        const node = cloneDeep(x.node);
                        node.type = node.type.toLowerCase();
                        node.subjects = node.subjects.edges.map(x => x.node);
                        return node;
                    });
                } else {
                    return this.allInsights;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van inzichten."
                    ),
                    type: "error"
                });
            }
        },
        groupSchedule: {
            query: gql`
                query allGroupSchedulesGroupScheduleDetail(
                    $date: Date!
                    $groupId: ID!
                ) {
                    allGroupSchedules(
                        date: $date
                        groupId: $groupId
                        first: 1
                    ) {
                        edges {
                            node {
                                id
                                notes
                                schedules {
                                    edges {
                                        node {
                                            id
                                            status
                                            grade {
                                                id
                                                grade
                                            }
                                            scheduleRows {
                                                edges {
                                                    node {
                                                        id
                                                        startTime
                                                        endTime
                                                        theme
                                                        notes
                                                        purpose
                                                        subject {
                                                            id
                                                            name
                                                            parent {
                                                                id
                                                                name
                                                            }
                                                        }
                                                        subjectBundle {
                                                            id
                                                            name
                                                            bundleLabelType
                                                        }
                                                        subjectDescription
                                                        isBreak
                                                        evaluation
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    date: this.dateISO,
                    groupId: this.groupId
                };
            },
            update(data) {
                if (data.allGroupSchedules) {
                    if (data.allGroupSchedules.edges.length === 0) return {};

                    const groupSchedule = {
                        ...data.allGroupSchedules.edges[0].node
                    };
                    groupSchedule.schedules = groupSchedule.schedules.edges.map(
                        x => {
                            return {
                                ...x.node,
                                scheduleRows: getScheduleRows(
                                    x.node.scheduleRows.edges,
                                    node => ({
                                        ...node,
                                        rawId: atob(node.id).split(":")[1],
                                        startTime: this.formatTime(
                                            node.startTime
                                        ),
                                        endTime: this.formatTime(node.endTime)
                                    }),
                                    node => ({
                                        id: node.id,
                                        rawId: atob(node.id).split(":")[1],
                                        startTime: this.formatTime(
                                            node.startTime
                                        ),
                                        endTime: this.formatTime(node.endTime),
                                        subjectId: node.subjectBundle.id,
                                        bundleRows: []
                                    }),
                                    false
                                )
                            };
                        }
                    );
                    this.formValues.notes = groupSchedule.notes;
                    return groupSchedule;
                } else {
                    return this.groupSchedule;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de planning."
                    ),
                    type: "error"
                });
            }
        }
    },
    watch: {
        createdInsights() {
            this.refetchInsights();
        },
        expiredInsights() {
            this.refetchInsights();
        },
        deletedInsights() {
            this.refetchInsights();
        },
        updatedInsights() {
            this.refetchInsights();
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        createSchedule() {
            const input = {
                date: this.dateISO,
                group: atob(this.groupId).split(":")[1]
            };
            this.loading++;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation groupScheduleAppScheduleCreateModal(
                            $input: GroupScheduleMutationInput!
                        ) {
                            groupSchedule(input: $input) {
                                groupSchedule {
                                    id
                                }
                            }
                        }
                    `,
                    variables: {
                        input: input
                    }
                })
                .then(data => {
                    if (data.data.groupSchedule) {
                        this.$router.push({
                            name: "group_schedule_update",
                            params: {
                                groupScheduleId:
                                    data.data.groupSchedule.groupSchedule.id
                            }
                        });
                        this.showMessage({
                            message: this.$gettext(
                                "Planning is succesvol aangemaakt."
                            ),
                            type: "success"
                        });
                    } else {
                        throw new Error("No schedule returned.");
                    }
                })
                .catch(err => {
                    consoleError(err);
                    this.showMessage({
                        message: this.$gettext(
                            "Er is een fout opgetreden bij het versturen, probeer het a.u.b. opnieuw."
                        ),
                        type: "error"
                    });
                })
                .finally(() => {
                    this.loading--;
                });
        },
        formatTime(val) {
            return this.$moment(val, "HHmm").format("HH:mm");
        },
        notesMutation(formValues) {
            const input = {
                id: this.numericId,
                notes: formValues.notes
            };
            return {
                mutation: gql`
                    mutation groupScheduleScheduleDetail(
                        $input: GroupScheduleMutationInput!
                    ) {
                        groupSchedule(input: $input) {
                            groupSchedule {
                                id
                                notes
                            }
                            errors {
                                field
                                messages
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            };
        },
        openClassView() {
            const route = this.$router.resolve({
                name: "group_schedule_class",
                params: {
                    date: this.$moment(this.date).format("DD-MM-YYYY"),
                    groupId: this.groupId
                }
            });
            const classView = window.open(
                route.href,
                "ClassView",
                "resizable=yes,scrollbars=yes,menubar=no,toolbar=no,location=no"
            );
            classView.focus();
        },
        refetchInsights() {
            this.$apollo.queries.allInsights.refetch();
        }
    }
};
</script>
