<template>
    <TheLayout class="layout--vertical">
        <AppHeader variant="levels">
            <AppTitle v-translate class="section-header__title u-margin-bottom">
                Onderwijsniveau
            </AppTitle>

            <div class="header-options">
                <AppInputSelect
                    v-if="gradeOptions.length > 1"
                    class="header-options__option"
                    :disabled="loading > 0"
                    hide-details
                    :options="gradeOptions"
                    options-label="label"
                    options-track-by="value"
                    :value="gradeOptions.find(x => x.value === internalGrade)"
                    @input="changeGrade"
                />

                <AppInputSelect
                    v-if="allEducationalLevels.length > 1"
                    class="header-options__option"
                    :disabled="loading > 0"
                    hide-details
                    :options="allEducationalLevels"
                    options-label="name"
                    options-track-by="name"
                    :value="
                        allEducationalLevels.find(
                            x => x.id === $route.params.subject
                        )
                    "
                    @input="changeLevel"
                />
            </div>
        </AppHeader>

        <AppLayoutMain>
            <router-view />
        </AppLayoutMain>
    </TheLayout>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppInputSelect from "@/components/AppInputSelect";
import AppLayoutMain from "@/components/AppLayoutMain";
import AppTitle from "@/components/AppTitle";
import TheLayout from "@/components/TheLayoutPage";

import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    name: "GroupLevel",
    components: {
        AppLayoutMain,
        AppTitle,
        AppHeader,
        AppInputSelect,
        TheLayout
    },
    props: {
        grade: {
            type: Number,
            default: undefined
        },
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allEducationalLevels: {},
            group: {},
            loading: 0
        };
    },
    computed: {
        gradeOptions() {
            return this.group.grades
                ? this.group.grades.map(x => ({
                      value: x,
                      label: this.$gettextInterpolate("Groep %{grade}", {
                          grade: x
                      })
                  }))
                : [];
        },
        internalGrade() {
            return this.grade
                ? this.grade
                : this.group.grades
                ? this.group.grades[0]
                : undefined;
        }
    },
    apollo: {
        allEducationalLevels: {
            query: gql`
                query allEducationalLevelsGroupLevel(
                    $groupId: ID!
                    $grade: Int
                ) {
                    allEducationalLevels(groupId: $groupId, grade: $grade) {
                        edges {
                            node {
                                id
                                subject {
                                    id
                                    parent {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    groupId: this.groupId,
                    grade: this.internalGrade
                };
            },
            update(data) {
                if (data.allEducationalLevels) {
                    return data.allEducationalLevels.edges
                        .map(x => x.node.subject.parent)
                        .filter(
                            (level, index, levels) =>
                                index ===
                                levels.findIndex(x => x.id === level.id)
                        )
                        .sort((a, b) => {
                            const nameA = a.name.toUpperCase();
                            const nameB = b.name.toUpperCase();
                            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                        });
                } else {
                    return this.allEducationalLevels;
                }
            },
            skip() {
                return !this.group.id;
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van onderwijsniveaus."
                    ),
                    type: "error"
                });
            }
        },
        group: {
            query: gql`
                query groupGroupLevel($id: ID!) {
                    group(id: $id) {
                        id
                        grades
                    }
                }
            `,
            variables() {
                return {
                    id: this.groupId
                };
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van onderwijsniveaus."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        changeGrade(value) {
            if (!value) return;

            this.$router.push({
                name: "group_level",
                params: {
                    grade: value.value
                }
            });
        },
        changeLevel(value) {
            if (!value) return;

            this.$router.push({
                name: "group_level_detail",
                params: {
                    subject: value.id,
                    grade: this.internalGrade
                }
            });
        }
    }
};
</script>
