<template>
    <AppLayout variant="vertical">
        <AppLayoutInner variant="background centered">
            <AppOverviewPlaceholder variant="small">
                <AppTitle v-translate class="overview-placeholder__title">
                    Rapporten
                </AppTitle>
                <p v-translate>
                    Dit genereert nieuwe rapporten voor alle leerlingen in deze
                    klas.
                </p>
                <p v-translate>
                    De onderwijsniveau's in de rapporten blijven up-to-date
                    totdat de rapporten op definitief worden gezet.
                </p>
                <template slot="button">
                    <AppButton
                        variant="primary medium-with-icon"
                        icon="plus"
                        :disabled="!onLine"
                        :loading="loading > 0"
                        @click="generateReports"
                    >
                        <translate>Genereer rapporten</translate>
                    </AppButton>
                </template>
            </AppOverviewPlaceholder>
        </AppLayoutInner>
    </AppLayout>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppLayout from "@/components/AppLayout.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppTitle from "@/components/AppTitle.vue";
import GenerateReports from "@/mixins/GenerateReports";
import { mapGetters } from "vuex";

export default {
    name: "ReportEmpty",
    components: {
        AppTitle,
        AppLayoutInner,
        AppLayout,
        AppButton,
        AppOverviewPlaceholder
    },
    mixins: [GenerateReports],
    computed: mapGetters("online", ["onLine"])
};
</script>
