<template>
    <AppModal
        ref="modal"
        :value="value"
        has-form
        @modalSubmit="formSubmit"
        @input="$emit('input', $event)"
    >
        <div slot="title">
            <translate>Dag toevoegen</translate>
        </div>

        <AppFormGroup>
            <AppRow variant="gutters-tiny">
                <AppColumn>
                    <AppInputDate
                        v-model="formValues.date"
                        :disabled="loading > 0"
                        :disabled-dates="disabledDates"
                        :error-messages="
                            formErrors.date.length
                                ? formErrors.date
                                : formErrors.all
                        "
                        :label="$gettext('Datum')"
                        required
                        :hide-details="!formErrors.date.length"
                    />
                </AppColumn>
            </AppRow>
        </AppFormGroup>

        <template #footer>
            <AppButton
                class="d-flex--align-right"
                variant="secondary huge"
                :disabled="!onLine"
                :loading="loading > 0"
                type="submit"
            >
                <translate>Opslaan</translate>
            </AppButton>
        </template>
    </AppModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppColumn from "@/components/AppColumn.vue";
import AppFormGroup from "@/components/AppFormGroup.vue";
import AppInputDate from "@/components/AppInputDate";
import AppModal from "@/components/AppModal";
import AppRow from "@/components/AppRow.vue";
import FormSave from "@/mixins/FormSave";
import { cloneDeep } from "lodash";
import { consoleError } from "@/console";
import gql from "graphql-tag";

export default {
    name: "AppScheduleCreateModal",
    components: {
        AppColumn,
        AppRow,
        AppFormGroup,
        AppButton,
        AppInputDate,
        AppModal
    },
    mixins: [FormSave],
    props: {
        groupId: {
            type: String,
            required: true
        },
        value: Boolean
    },
    data() {
        return {
            allGroupSchedules: [],
            formValues: {
                date: new Date()
            },
            emptyFormErrors: {
                all: [],
                date: []
            }
        };
    },
    computed: {
        ...mapGetters("online", ["onLine"]),
        disabledDates() {
            return {
                to: this.$moment().toDate(),
                dates: this.allGroupSchedules.map(x =>
                    this.$moment(x.date).toDate()
                )
            };
        }
    },
    apollo: {
        allGroupSchedules: {
            query: gql`
                query allGroupSchedulesGroupSchedule($groupId: ID!) {
                    allGroupSchedules(groupId: $groupId) {
                        edges {
                            node {
                                id
                                date
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    groupId: this.groupId
                };
            },
            update(data) {
                if (data.allGroupSchedules) {
                    const schedules = data.allGroupSchedules.edges.map(
                        x => x.node
                    );
                    this.cleanDateValue();
                    return schedules;
                } else {
                    return this.allGroupSchedules;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de planning."
                    ),
                    type: "error"
                });
            }
        }
    },
    watch: {
        value(value) {
            if (value === true) {
                this.formValues.date = new Date();
                this.formErrors = cloneDeep(this.emptyFormErrors);
                this.cleanDateValue();
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        cleanDateValue() {
            if (
                this.formValues.date &&
                this.allGroupSchedules.findIndex(x =>
                    this.$moment(x.date).isSame(this.formValues.date, "day")
                ) !== -1
            ) {
                this.formValues.date = null;
            }
        },
        getMutation() {
            const input = {
                date: this.$moment(this.formValues.date).format("YYYY-MM-DD"),
                group: atob(this.groupId).split(":")[1]
            };

            return {
                mutation: gql`
                    mutation groupScheduleAppScheduleCreateModal(
                        $input: GroupScheduleMutationInput!
                    ) {
                        groupSchedule(input: $input) {
                            groupSchedule {
                                id
                            }
                            errors {
                                field
                                messages
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            };
        },
        formSuccess(data) {
            this.$emit("created", data.data.groupSchedule.groupSchedule.id);
            this.showMessage({
                message: this.$gettext("Planning is succesvol aangemaakt."),
                type: "success"
            });
        }
    }
};
</script>
