<template>
    <TheLayout class="layout--vertical">
        <AppLayoutInner variant="background">
            <AppForm ref="form" lazy-validation @submit.prevent="formSubmit">
                <AppPageHeader header-variant="centered">
                    <AppTitle
                        v-if="groupSchedule.id"
                        class="subtitle header__title"
                    >
                        <AppLink
                            :to="{
                                name: 'group_schedule_detail',
                                params: {
                                    date: $moment(groupSchedule.date).format(
                                        'DD-MM-YYYY'
                                    )
                                }
                            }"
                            class="u-margin-right-small"
                        >
                            <AppIcon
                                name="arrow"
                                variant="tiny"
                                class="u-margin-right-small u-rotate-90"
                            />
                            <!-- prettier-ignore -->
                            <AppText
                                v-translate="{
                                    grade: groupSchedule.grade,
                                    date: $moment(groupSchedule.date).format(
                                        'dddd LL'
                                    )
                                }"
                                variant="date"
                            >
                                %{date} - Groep %{grade}
                            </AppText>
                        </AppLink>
                    </AppTitle>
                    <AppButtonGroup class="u-margin-left-auto" gap="large">
                        <AppButton
                            v-if="!isScheduleFinal"
                            variant="link link-underline link-large link-bold link-font-primary"
                            :disabled="!onLine"
                            :loading="loading > 0"
                            :title="$gettext('Opslaan als concept')"
                            type="submit"
                            @click="scheduleStatus = 'draft'"
                        >
                            <translate>Opslaan als concept</translate>
                        </AppButton>

                        <AppButton
                            data-testid="button"
                            variant="primary medium"
                            :disabled="!onLine || disableFinalize"
                            icon="check-small"
                            :loading="loading > 0"
                            :title="$gettext('Planning is compleet')"
                            type="submit"
                            @click="scheduleStatus = 'final'"
                        >
                            <translate>Planning is compleet</translate>
                        </AppButton>
                    </AppButtonGroup>
                </AppPageHeader>

                <AppScheduleGroupWrapper v-if="groupSchedule.schedules.length">
                    <AppScheduleGroup
                        :style="
                            `grid-template-columns: repeat(${groupSchedule.schedules.length}, minmax(calc(50% - 6px), 1fr))`
                        "
                    >
                        <div
                            v-for="schedule in groupSchedule.schedules"
                            :key="schedule.id"
                        >
                            <AppHeader v-if="schedule.grade" variant="small">
                                <translate
                                    tag="h3"
                                    class="subtitle-small"
                                    :translate-params="{
                                        grade: schedule.grade.grade
                                    }"
                                >
                                    Groep %{grade}
                                </translate>
                            </AppHeader>
                            <AppScheduleUpdate
                                ref="schedules"
                                :form-instance="$refs.form"
                                :schedule-id="schedule.id"
                                :schedule-status="
                                    scheduleStatus ||
                                        schedule.status.toLowerCase()
                                "
                                @disable-finalize="disableFinalize = false"
                            />
                        </div>
                    </AppScheduleGroup>
                </AppScheduleGroupWrapper>
            </AppForm>

            <hr class="hr--large bg-blue-shade-light u-margin-bottom-small" />

            <div class="t-align-right">
                <AppButton
                    data-testid="delete-button"
                    variant="link"
                    :disabled="!onLine"
                    :loading="loading > 0"
                    @click="confirmDelete"
                >
                    <AppIcon name="trash" class="u-margin-right-tiny" />
                    <translate>Dag verwijderen</translate>
                </AppButton>
            </div>
        </AppLayoutInner>
    </TheLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppButtonGroup from "@/components/AppButtonGroup.vue";
import AppForm from "@/components/AppForm";
import AppHeader from "@/components/AppHeader.vue";
import AppIcon from "@/components/AppIcon";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppLink from "@/components/AppLink.vue";
import AppPageHeader from "@/components/AppPageHeader.vue";
import AppScheduleGroup from "@/components/AppScheduleGroup.vue";
import AppScheduleGroupWrapper from "@/components/AppScheduleGroupWrapper.vue";
import AppScheduleUpdate from "@/components/AppScheduleUpdate";
import AppText from "@/components/AppText.vue";

import AppTitle from "@/components/AppTitle.vue";
import TheLayout from "@/components/TheLayoutDefault";
import { consoleError } from "@/console";
import gql from "graphql-tag";

export default {
    name: "GroupScheduleUpdate",
    components: {
        AppButtonGroup,
        AppScheduleGroup,
        AppScheduleGroupWrapper,
        AppPageHeader,
        AppLink,
        AppTitle,
        AppHeader,
        AppLayoutInner,
        AppText,
        AppButton,
        AppIcon,
        AppForm,
        AppScheduleUpdate,
        TheLayout
    },
    props: {
        groupScheduleId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: 0,
            scheduleStatus: "",
            disableFinalize: true,
            groupSchedule: {
                grades: [],
                group: {},
                schedules: []
            }
        };
    },
    computed: {
        ...mapGetters("online", ["onLine"]),
        numericId() {
            return atob(this.groupScheduleId).split(":")[1];
        },
        isScheduleFinal() {
            return this.groupSchedule.schedules.every(
                schedule => schedule.status === "FINAL"
            );
        }
    },
    apollo: {
        groupSchedule: {
            query: gql`
                query groupScheduleGroupScheduleUpdate($id: ID!) {
                    groupSchedule(id: $id) {
                        id
                        date
                        group {
                            id
                            grades
                        }
                        schedules {
                            edges {
                                node {
                                    id
                                    status
                                    grade {
                                        id
                                        grade
                                    }
                                }
                            }
                        }
                        modified
                    }
                }
            `,
            variables() {
                return {
                    id: this.groupScheduleId
                };
            },
            update(data) {
                if (data.groupSchedule) {
                    const schedule = { ...data.groupSchedule };
                    schedule.grade = schedule.group.grades.join("/");
                    schedule.schedules = schedule.schedules.edges.map(
                        x => x.node
                    );
                    return schedule;
                } else {
                    return this.groupSchedule;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van de planning."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        confirmDelete() {
            if (
                confirm(
                    this.$gettext(
                        "Weet u zeker dat u deze planning wilt verwijderen?"
                    )
                )
            ) {
                this.loading++;
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation deleteGroupSchedule($id: ID!) {
                                deleteGroupSchedule(
                                    input: { groupSchedule: $id }
                                ) {
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        `,
                        variables: {
                            id: this.numericId
                        }
                    })
                    .then(data => {
                        let errors = [];
                        for (const k in data.data) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    data.data,
                                    k
                                )
                            ) {
                                errors = errors.concat(
                                    data.data[k].errors || []
                                );
                            }
                        }

                        if (errors.length === 0) {
                            this.showMessage({
                                message: this.$gettext(
                                    "Planning is succesvol verwijderd."
                                ),
                                type: "success"
                            });
                            this.$router.push({
                                name: "group_schedule"
                            });
                        } else {
                            this.formError();
                        }
                    })
                    .catch(err => {
                        this.formError(err);
                    })
                    .finally(() => {
                        this.loading--;
                    });
            }
        },
        formSubmit() {
            if (!this.$refs.schedules) return;
            this.loading++;
            Promise.all(
                this.$refs.schedules.map(schedule => schedule.formSubmit(true))
            )
                .then(() => {
                    if (this.scheduleStatus === "final") {
                        this.$router.push({
                            name: "group_schedule_detail",
                            params: {
                                date: this.$moment(
                                    this.groupSchedule.date
                                ).format("DD-MM-YYYY")
                            }
                        });
                    }
                    this.formSuccess();
                })
                .catch(() => {})
                .finally(() => {
                    this.loading--;
                });
        },
        formSuccess() {
            const message =
                this.scheduleStatus === "draft"
                    ? "Planning is succesvol opgeslagen als concept."
                    : "Wijzigingen zijn succesvol opgeslagen.";
            this.showMessage({
                message: this.$gettext(message),
                type: "success"
            });
        }
    }
};
</script>
