var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppLayoutInner',{attrs:{"variant":!_vm.groupSchedule.id ? 'centered' : ''}},[(_vm.groupSchedule.id)?[_c('AppPageHeader',{attrs:{"variant":"centered"}},[_c('div',{staticClass:"d-flex"},[_c('AppTitle',{staticClass:"subtitle",attrs:{"as":"h2"}},[_c('AppText',{staticClass:"text--date"},[_vm._v(" "+_vm._s(_vm.dateName)+" ")])],1),_c('AppLink',{staticClass:"page-header__button",attrs:{"variant":"small","to":{
                        name: 'group_schedule_update',
                        params: { groupScheduleId: _vm.groupSchedule.id }
                    }}},[_c('AppIcon',{staticClass:"icon--small u-margin-right-small",attrs:{"name":"edit"}}),_c('translate',[_vm._v("Bewerken")])],1)],1),_c('div',{staticClass:"u-margin-left-auto"},[_c('AppButton',{staticClass:"page-header__button",attrs:{"icon":"out","variant":"link link-small"},on:{"click":function($event){$event.preventDefault();return _vm.openClassView.apply(null, arguments)}}},[_c('translate',[_vm._v("Klasweergave")])],1)],1)]),(_vm.groupedScheduleRows.length)?_c('AppScheduleGroupWrapper',_vm._l((_vm.groupedScheduleRows),function(scheduleGroup,index){return _c('AppScheduleGroup',{key:("schedule-group-" + index),attrs:{"columns-missing-schedule-rows":_vm.columnsMissingScheduleRows,"group-id":_vm.groupId,"group-schedule":_vm.groupSchedule,"index":index,"schedule-grades":_vm.scheduleGrades,"schedule-group":scheduleGroup}})}),1):_c('AppMessage',{staticClass:"u-margin-bottom-large",attrs:{"variant":"rounded info"},scopedSlots:_vm._u([{key:"options",fn:function(){return [(_vm.groupSchedule)?_c('AppLink',{attrs:{"variant":"white small","to":{
                        name: 'group_schedule_update',
                        params: { groupScheduleId: _vm.groupSchedule.id }
                    }}},[_c('AppIcon',{staticClass:"u-margin-right-small",attrs:{"name":"edit","variant":"small"}}),_c('translate',[_vm._v("Planning compleet maken")])],1):_vm._e()]},proxy:true}],null,false,1615254093)},[_c('translate',[_vm._v("De planning is nog niet compleet.")])],1),_c('AppContent',{attrs:{"variant":"rounded shadow"}},[_c('AppRow',[_c('AppColumn',{staticClass:"column-full--lte-tablet-portrait"},[_c('AppHeader',{attrs:{"variant":"centered"}},[_c('AppTitle',{staticClass:"subtitle header__subtitle",attrs:{"as":"h2"}},[_c('AppIcon',{staticClass:"u-margin-right-small",attrs:{"name":"note"}}),_c('translate',[_vm._v("Notities")])],1)],1),_c('AppSingleTextareaForm',{attrs:{"counter":560,"disabled":_vm.loading > 0 || !_vm.onLine,"field":"notes","form-mutation":_vm.notesMutation,"hint":_vm.$gettext(
                                'Ruimte voor een algemene notitie voor deze dag.'
                            ),"persistent-hint":""},on:{"formsuccess":function($event){_vm.showMessage({
                                message: _vm.$gettext(
                                    'Notities zijn succesvol opgeslagen.'
                                ),
                                type: 'success'
                            })}},model:{value:(_vm.formValues.notes),callback:function ($$v) {_vm.$set(_vm.formValues, "notes", $$v)},expression:"formValues.notes"}})],1),_c('AppColumn',{staticClass:"column-full--lte-tablet-portrait"},[_c('AppHeader',[_c('h2',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"subtitle"},[_vm._v(" Inzichten ")])]),_c('AppInsightsWrapper',{attrs:{"variant":"bordered"}},[(_vm.allInsightsGroupedByBulkId.length)?_vm._l((_vm.allInsightsGroupedByBulkId),function(insight){return _c('AppInsight',{key:insight.id,attrs:{"context":"schedule","group-id":_vm.groupId,"insight":insight}})}):(!_vm.loading)?_c('p',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"t-color-blue-light"},[_vm._v(" Er zijn geen inzichten voor deze dag. ")]):_vm._e()],2)],1)],1)],1)]:(!_vm.loading)?[_c('AppOverviewPlaceholder',{key:"noSchedule",staticClass:"overview-placeholder--small",scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('AppButton',{attrs:{"data-testid":"button-create-schedule","variant":"primary medium-with-icon","icon":"plus","disabled":!_vm.onLine || _vm.isHistoric,"loading":_vm.loading > 0},on:{"click":_vm.createSchedule}},[_c('translate',[_vm._v("Dag toevoegen")])],1)]},proxy:true}])},[_c('AppTitle',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"overview-placeholder__title",attrs:{"size":"large"}},[_vm._v(" Geen planning ")]),_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({ date: _vm.dateName.toLowerCase() }),expression:"{ date: dateName.toLowerCase() }"}]},[_vm._v(" Er is geen planning voor %{date} toegevoegd ")])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }