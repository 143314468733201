import { render, staticRenderFns } from "./EducationPlans.vue?vue&type=template&id=7f0bf542&"
import script from "./EducationPlans.vue?vue&type=script&lang=js&"
export * from "./EducationPlans.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports