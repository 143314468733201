<template>
    <AppLayout variant="vertical">
        <AppLayoutInner variant="background centered">
            <AppOverviewPlaceholder variant="small">
                <AppTitle v-translate class="overview-placeholder__title">
                    Geen onderwijsniveaus
                </AppTitle>
                <p v-translate="{ student: student.fullName }">
                    %{student} heeft nog geen onderwijsniveaus.
                </p>
            </AppOverviewPlaceholder>
        </AppLayoutInner>
    </AppLayout>
</template>

<script>
import AppLayout from "@/components/AppLayout.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppTitle from "@/components/AppTitle.vue";
import gql from "graphql-tag";

export default {
    name: "LevelEmpty",
    components: { AppTitle, AppLayoutInner, AppLayout, AppOverviewPlaceholder },
    props: {
        studentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: 0,
            student: { fullName: "" }
        };
    },
    apollo: {
        student: {
            query: gql`
                query studentLevelEmpty($id: ID!) {
                    student(id: $id) {
                        id
                        fullName
                    }
                }
            `,
            variables() {
                return {
                    id: this.studentId
                };
            },
            fetchPolicy: "cache-first"
        }
    }
};
</script>
