<template>
    <div>
        <AppSingleTextareaForm
            v-model="formValues.notes"
            :counter="560"
            :disabled="loading > 0 || !onLine"
            field="notes"
            :form-mutation="notesMutation"
            :hint="
                $gettext('Omschrijf hier opvallende dingen binnen deze groep.')
            "
            icon="note"
            size="large"
            persistent-hint
            @formsuccess="formSuccess"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppSingleTextareaForm from "@/components/AppSingleTextareaForm";
import FormSave from "@/mixins/FormSave";
import gql from "graphql-tag";

export default {
    name: "GroupOverviewGeneral",
    components: {
        AppSingleTextareaForm
    },
    mixins: [FormSave],
    props: {
        groupId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            group: {},
            formValues: {
                notes: ""
            }
        };
    },
    computed: mapGetters("online", ["onLine"]),
    apollo: {
        group: {
            query: gql`
                query($id: ID!) {
                    group(id: $id) {
                        id
                        notes
                    }
                }
            `,
            variables() {
                return {
                    id: this.groupId
                };
            },
            update(data) {
                if (data.group) {
                    const group = { ...data.group };
                    this.formValues["notes"] = group.notes;
                    return group;
                } else {
                    return this.group;
                }
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        notesMutation(formValues) {
            const input = {
                id: atob(this.groupId).split(":")[1],
                notes: formValues.notes,
                documentsTotalForms: 0,
                documentsInitialForms: 0
            };
            return {
                mutation: gql`
                    mutation groupNotesOverview($input: GroupMutationInput!) {
                        group(input: $input) {
                            group {
                                id
                                notes
                            }
                            errors {
                                ... on ErrorType {
                                    field
                                    messages
                                }
                                ... on ErrorTypeFormset {
                                    field
                                    formIndex
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            };
        },
        formSuccess() {
            this.showMessage({
                message: this.$gettext("Notities zijn succesvol opgeslagen."),
                type: "success"
            });
        }
    }
};
</script>
