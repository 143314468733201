<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppPurpose",
    setup() {
        const { antlerClass } = antlerComponent("purpose");

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.purpose
    display: flex
    align-items: center
    justify-content: center
    padding: $spacing-xsmall $spacing-regular

    color: #fff
    font:
        weight: $strong-weight
        family: $primary-font-family
    text-align: center

    background-color: $color-blue-shade
    border-radius: $global-border-radius-rounded
</style>
